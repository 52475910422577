import React, { FC, CSSProperties } from 'react'

const BrDiv: FC<{ style: CSSProperties; text: string }> = ({
  style = {},
  text = '',
}) => {
  return (
    <div
      style={{ ...style }}
      dangerouslySetInnerHTML={{ __html: text.replaceAll('\n', '<br />') }}
    ></div>
  )
}

export default BrDiv
