import React, { FC } from 'react'
import useIsMobile from 'app/hooks'

const NewDetail: FC = () => {
  const isMobile = useIsMobile()

  const news = sessionStorage.getItem('_icec_news')

  return (
    <div
      style={
        isMobile ? { margin: '0 30px', fontSize: '10px' } : { margin: '0 80px' }
      }
      dangerouslySetInnerHTML={{ __html: news || '' }}
    ></div>
  )
}

export default NewDetail
