const Message = {
  new_registration: 'Sign Up',
  login: 'Sign In',
  user_name: 'Name',
  contact_email: 'Email',
  contact_phone: 'Phone',
  contact_content: 'Description',
  company_name: 'Company',
  school_name: 'School',
  position: 'Department/Position',
  person_cooperation: 'Contact Us',
  company_cooperation: 'For Company',
  school_cooperation: 'For School',
  submit: 'Submit',
  submit_success: 'Submission Successful',
  please_input: 'Please Input',
  contact_us: 'Contact Us Now',
}

export default Message
