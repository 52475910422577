import { useState } from 'react'
import { createContainer } from 'unstated-next'

import { getLanguage, storageLanguage } from 'app/utils'

const useLanguage = () => {
  const defaultLanguage = getLanguage()

  const [language, setLanguage] = useState<string>(defaultLanguage)

  return {
    language,
    changeLanguage: (language: string) => {
      storageLanguage(language)
      setLanguage(language)
    },
  }
}

export default createContainer(useLanguage)
