import React, { FC, CSSProperties } from 'react'
import { Spin } from 'antd'

const Loading: FC<{ style: CSSProperties }> = ({ style = {} }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        ...style,
      }}
    >
      <Spin />
    </div>
  )
}

export default Loading
