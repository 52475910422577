import React, { FC, useEffect, useState } from 'react'

import { getPolicy } from './api'
import Language from 'app/container/language'
import useIsMobile from 'app/hooks'
import './index.less'

const PrivacyPolicy: FC = () => {
  const [data, setData] = useState<any>()
  const isMobile = useIsMobile()
  const { language } = Language.useContainer()

  useEffect(() => {
    getPolicy(language).then((res) => setData(res))
  }, [language])
  return (
    <div
      className="privacyStyle"
      style={
        isMobile ? { margin: '0 30px', fontSize: '10px' } : { margin: '0 80px' }
      }
      dangerouslySetInnerHTML={{ __html: data?.[0]?.attributes.content || '' }}
    ></div>
  )
}

export default PrivacyPolicy
