import { httpMeta, http } from 'app/http/instance'

export const getNewsCategory = (locale: string) =>
  http.get('/api/news-categories', { populate: '*', locale })

export const getAllNews = (
  locale: string,
  category: string,
  page: number,
  pageSize: number
) => {
  const _category = category === 'all' ? undefined : category
  return httpMeta.get('/api/news-commons', {
    populate: '*',
    'filters[category][key][$eq]': _category,
    'pagination[page]': page,
    'pagination[pageSize]': pageSize,
  })
}
