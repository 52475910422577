import { Grid } from 'antd'
const { useBreakpoint } = Grid

const useIsMobile = (): boolean => {
  const screens = useBreakpoint()
  if (typeof screens.md === 'undefined') {
    return false
  }
  return !screens.lg // 768px
}

export default useIsMobile
