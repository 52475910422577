import React, { FC, useState, useEffect } from 'react'
import { Row, Col, Pagination, Tabs, Spin } from 'antd'

import { BlockTitle } from 'app/pages/introduce/FunctionBlock'
import Picture from './Picture'
import { getNewsCategory, getAllNews } from './api'
import './index.less'
import useIsMobile from 'app/hooks'
import { getImgUrl } from 'app/utils'
import Language from 'app/container/language'
import Loading from 'app/components/Loading'
import { getBlockTitle } from 'app/pages/introduce/api'
import SeoMet from 'app/components/helMet'

const NewsDetail: FC<{ category: string }> = ({ category }) => {
  const [news, setNews] = useState<any>()
  const [page, setPage] = useState<number>(1)
  const isMobile = useIsMobile()
  const { language } = Language.useContainer()

  useEffect(() => {
    getAllNews(language, category, page, 10).then((res) => setNews(res))
  }, [category, page, language])

  return (
    <div>
      {isMobile ? (
        <div>
          {news ? (
            news?.data
              .sort((a: any, b: any) => a.attributes.sort - b.attributes.sort)
              .map((tt: any, index: number) => (
                <div
                  style={{
                    display: 'flex',
                    height: '94px',
                    cursor: 'pointer',
                    marginTop: index === 0 ? '0' : '24px',
                    borderBottom:
                      index < news.data.length - 1 ? '0.2px solid #B1B1B1' : '',
                  }}
                  onClick={() => {
                    sessionStorage.setItem('_icec_news', tt.attributes?.content)
                    window.open('/news-detail')
                  }}
                >
                  <div style={{ marginRight: '20px' }}>
                    <div
                      style={{
                        color: '#2B2525',
                        fontSize: '12px',
                        fontWeight: '600',
                        lineHeight: '20px',
                        width: 'calc(100% - 20px)',
                        marginBottom: '4px',
                      }}
                      className="textOverflow"
                    >
                      {tt.attributes.name}
                    </div>
                    <div style={{ color: '#4D4D4D', fontSize: '10px' }}>
                      {tt.attributes.date}
                    </div>
                  </div>
                  <img
                    src={getImgUrl(tt.attributes.image)}
                    width={110}
                    height={82}
                    style={{ borderRadius: '4px' }}
                    loading="lazy"
                    alt=""
                  />
                </div>
              ))
          ) : (
            <Loading style={{ height: '320px' }} />
          )}
        </div>
      ) : (
        <Row gutter={28} style={{ marginTop: '48px' }}>
          {news ? (
            news?.data
              .sort((a: any, b: any) => a.attributes.sort - b.attributes.sort)
              .map((tt: any) => (
                <Col key={tt.id}>
                  <Picture data={tt.attributes} />
                </Col>
              ))
          ) : (
            <Loading style={{ height: '320px', width: '100%' }} />
          )}
        </Row>
      )}

      <div style={{ textAlign: 'center', marginBottom: '62px' }}>
        <Pagination
          size={isMobile ? 'small' : 'default'}
          total={news?.meta.pagination.total}
          current={news?.meta.pagination.page}
          onChange={(page: number) => setPage(page)}
        />
      </div>
    </div>
  )
}

const News: FC = () => {
  const [categories, setCategories] = useState<any>()
  const { language } = Language.useContainer()
  const [blockTitle, setBlockTitle] = useState<any>([])

  useEffect(() => {
    getBlockTitle(language).then((res) => setBlockTitle(res?.[0]?.attributes))
  }, [language])

  useEffect(() => {
    getNewsCategory(language).then((res) => setCategories(res))
  }, [language])

  const isMobile = useIsMobile()

  const items = categories
    ?.sort((a: any, b: any) => a.attributes.sort - b.attributes.sort)
    .map((tt: any) => {
      return {
        key: tt.attributes.key,
        label: tt.attributes.category,
        children: <NewsDetail category={tt.attributes.key} />,
      }
    })

  return (
    <div className={isMobile ? 'newsWrapperMobile' : 'newsWrapper'}>
      <SeoMet locale={language} type={'news'} />
      <BlockTitle text={blockTitle?.title_news} />
      {categories ? (
        <Tabs
          items={items}
          defaultActiveKey={items?.[0] ? items[0].key : ''}
          style={{ marginTop: isMobile ? '20px' : '48px' }}
        />
      ) : (
        <Loading style={{ width: '100%', height: '320px' }} />
      )}
    </div>
  )
}

export default News
