import React, { FC, useState, useEffect } from 'react'
import { Carousel, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

import { BlockTitle } from 'app/pages/introduce/FunctionBlock'
import Language from 'app/container/language'
import { getTopNews } from './api'
import { getImgUrl } from 'app/utils'
import useIsMobile from 'app/hooks'

const ImgCard: FC<{ data: any }> = ({ data }) => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  if (isMobile) {
    return (
      <div style={{ height: '417px' }}>
        {data?.news
          .sort((a: any, b: any) => a.sort - b.sort)
          .map((tt: any, index: number) => {
            return (
              <div key={tt.id}>
                <div
                  style={{
                    display: 'flex',
                    paddingBottom: '12px',
                    marginTop: index === 0 ? '0' : '20px',
                    boxSizing: 'border-box',
                    borderBottom:
                      index !== data.news.length - 1
                        ? '0.2px solid #B1B1B1'
                        : 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    sessionStorage.setItem('_icec_news', tt?.content)
                    window.open('/news-detail')
                  }}
                >
                  <div>
                    <div
                      className="textOverflow"
                      style={{
                        color: '#2B2525',
                        fontSize: '12px',
                        fontWeight: '600',
                        width: 'calc(100% - 20px)',
                        marginBottom: '8px',
                      }}
                    >
                      {tt.title}
                    </div>
                    <div style={{ color: '#4D4D4D', fontSize: '10px' }}>
                      {tt.date}
                    </div>
                  </div>
                  <div>
                    <img
                      src={getImgUrl(tt.image)}
                      width={'110px'}
                      height={82}
                      style={{ borderRadius: '4px', overflow: 'hidden' }}
                      alt=""
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            )
          })}
        <div style={{ textAlign: 'right', marginTop: '24px' }}>
          <Button
            type="link"
            style={{ fontSize: '12px' }}
            onClick={() => navigate('/news')}
          >
            {`${data?.more_title} >`}
          </Button>
        </div>
      </div>
    )
  }
  const [main, ...rest] = data?.news.sort((a: any, b: any) => a.sort - b.sort)
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            width: 576,
            height: '594px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            cursor: 'pointer',
          }}
          onClick={() => {
            sessionStorage.setItem('_icec_news', main?.content)
            window.open('/news-detail')
          }}
        >
          <img
            src={getImgUrl(main.image)}
            width={576}
            height={432}
            loading="lazy"
            alt=""
            style={{ borderRadius: '8px', overflow: 'hidden' }}
          />
          <div
            style={{
              color: '#2B2525',
              fontSize: '20px',
              fontWeight: '600',
            }}
          >
            {main?.title}
          </div>
          <div style={{ color: '#4D4D4D', fontSize: '14px' }}>
            {main?.date || '2023年9月20日'}
          </div>
        </div>
        <div
          style={{
            marginLeft: '56px',
          }}
        >
          {rest.map((tt: any, index: number) => (
            <div
              key={tt.id}
              style={{
                display: 'flex',
                height: '170px',
                marginTop: index === 0 ? '0' : '20px',
                borderBottom: index < 2 ? '1px solid #B1B1B1' : '',
                cursor: 'pointer',
              }}
              onClick={() => {
                sessionStorage.setItem('_icec_news', tt?.content)
                window.open('/news-detail')
              }}
            >
              <img
                src={getImgUrl(tt.image)}
                width={177}
                height={133}
                loading="lazy"
                alt=""
                style={{ borderRadius: '8px', overflow: 'hidden' }}
              />
              <div style={{ marginLeft: '23px' }}>
                <div
                  style={{
                    color: '#2B2525',
                    fontSize: '20px',
                    fontWeight: '600',
                    width: '322px',
                    paddingTop: '20px',
                    marginBottom: '8px',
                  }}
                >
                  {tt.title ||
                    '全球重磅赛事-首届海外华裔青少年中华文化实践大赛日本赛区报名啦!'}
                </div>
                <div style={{ color: '#4D4D4D', fontSize: '14px' }}>
                  {tt.date || '2023年9月20日'}
                </div>
              </div>
            </div>
          ))}
          <div style={{ textAlign: 'right' }}>
            <Button
              type="link"
              style={{ fontSize: '20px' }}
              onClick={() => navigate('/news')}
            >
              {`${data?.more_title} >`}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const Content: FC = () => {
  const [data, setData] = useState<any>([])
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()

  useEffect(() => {
    getTopNews(language).then((res) => setData(res))
  }, [language])

  return (
    <div
      style={
        isMobile
          ? {
              margin: '48px auto 0',
              width: '330px',
            }
          : {
              margin: '124px auto 0',
              maxWidth: '1200px',
            }
      }
    >
      <BlockTitle
        text={data[0]?.attributes.title || 'ニュース、イベント情報'}
      />
      <Carousel
        style={{
          width: '100%',
          marginTop: isMobile ? '20px' : '84px',
        }}
        dotPosition={data.length > 1 ? 'right' : undefined}
        autoplay
        autoplaySpeed={3000}
      >
        {data
          ?.sort((a: any, b: any) => a.attributes.sort - b.attributes.sort)
          .map((tt: any) => (
            <ImgCard key={tt.id} data={tt.attributes} />
          ))}
      </Carousel>
    </div>
  )
}

export default Content
