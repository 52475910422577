const Message = {
  new_registration: '新規登録',
  login: 'ログイン',
  user_name: 'お名前',
  contact_email: 'メールアドレス',
  contact_phone: '電話番号',
  contact_content: 'お問い合わせ内容',
  company_name: '企業・団体名',
  school_name: '学校名',
  position: '部署名・役職',
  person_cooperation: 'お問い合わせ',
  company_cooperation: '企業向け',
  school_cooperation: '学校向け',
  submit: '送信',
  submit_success: '送信しました。',
  please_input: 'を入力してください',
  contact_us: 'お問い合わせ',
}

export default Message
