export const LanguageOptions = [
  {
    label: '日本語',
    value: 'ja-JP',
  },
  {
    label: '中文',
    value: 'zh-CN',
  },
  {
    label: 'English',
    value: 'en',
  },
]

export const storageLanguage = (locale: string) =>
  sessionStorage.setItem('_iece_la', locale)

export const getLanguage = () => {
  const urlLocal = new URLSearchParams(window.location.search).get('locale')

  const language = sessionStorage.getItem('_iece_la') || urlLocal || 'ja-JP'

  return language
}

export const isEnglish = (locale: string) => {
  if (!locale) return false
  const reg = /^[a-zA-Z\s]+$/
  return reg.test(locale)
}

const isTest =
  process.env.NODE_ENV === 'development' ||
  window.location.origin.indexOf('beta.icec.co.jp') !== -1

// const isTest = false

export const FormUrl = isTest
  ? 'https://api-beta.icec.co.jp'
  : 'https://api.icec.co.jp'

export const CMSUrl = isTest
  ? 'https://cms-beta.icec.co.jp'
  : 'https://cms.icec.co.jp'

export const StudentUrl = isTest
  ? 'https://stu-beta.icec.co.jp'
  : 'https://stu.icec.co.jp'

export const IECE_USER_URL_LOGIN = (locale: string) =>
  `${StudentUrl}/login?locale=${locale}`
export const IECE_USER_URL_REGISTER = (locale: string) =>
  `${StudentUrl}/register?locale=${locale}`
