import React, { FC } from 'react'

import './index.less'
import { getImgUrl } from 'app/utils'

type IPicture = { data: any }

const Picture: FC<IPicture> = ({ data }) => {
  return (
    <div
      style={{
        width: '382px',
        height: '464px',
        borderRadius: '12px',
        display: 'inline-grid',
        overflow: 'hidden',
        marginBottom: '48px',
        cursor: 'pointer',
      }}
      onClick={() => {
        sessionStorage.setItem('_icec_news', data?.content)
        window.open('/news-detail')
      }}
    >
      <img
        src={getImgUrl(data?.image)}
        alt=""
        width={384}
        height={288}
        loading="lazy"
      />
      <div style={{ padding: '24px 32px', background: '#E7F3FC' }}>
        <div
          style={{
            color: '#2B2525',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '26px',
          }}
        >
          {data?.name}
        </div>
        <div className="desc">{data?.summary}</div>
        <div className="desc">{data?.date}</div>
      </div>
    </div>
  )
}

export default Picture
