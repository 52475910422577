import React, { FC, useState, useEffect } from 'react'
import { Button, Spin } from 'antd'

import voice from 'app/asset/voice.svg'
import smallVoice from 'app/asset/smallVoice.svg'
import emotion from 'app/asset/emotion.svg'
import FunctionBlock from './FunctionBlock'
import Video from './Video'
import Cooperate from './Cooperate'
import VoiceOfUser from './VoiceOfUser'
import News from './News'
import './index.less'
import Language from 'app/container/language'
import { getMainBlock, getBlockTitle } from './api'
import useIsMobile from 'app/hooks'
import { IECE_USER_URL_REGISTER } from 'app/utils'
import CooperationForm from './components/CooperationForm'
import Loading from 'app/components/Loading'
import SeoMet from 'app/components/helMet'

const Introduce: FC = () => {
  const [data, setData] = useState<any>()
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()
  const [openType, setOpenType] = useState<'person' | 'company'>()
  const [blockTitle, setBlockTitle] = useState<any>([])

  useEffect(() => {
    getBlockTitle(language).then((res) => setBlockTitle(res?.[0]?.attributes))
  }, [language])

  useEffect(() => {
    getMainBlock(language).then((res) => setData(res?.[0]?.attributes))
  }, [language])

  return (
    <div
      style={{
        width: 'Se100%',
        marginTop: isMobile
          ? language === 'zh-CN'
            ? '116px'
            : '54px'
          : '66px',
      }}
    >
      <SeoMet locale={language} type={'homes'} />
      {data ? (
        <div
          style={{
            paddingLeft: isMobile ? '32px' : '80px',
            maxWidth: '1360px',
            margin: '0 auto',
          }}
        >
          <div
            className="title"
            style={
              isMobile
                ? {
                    fontSize: '42px',
                    letterSpacing: '4.3px',
                  }
                : { fontSize: '80px' }
            }
          >
            {data?.title}
          </div>
          <div
            className="titleTips"
            style={{
              fontSize: isMobile ? '24px' : '52px',
              letterSpacing: isMobile ? '0' : '7.54px',
            }}
          >
            {data?.subtitle}
          </div>
          <div style={{ marginTop: isMobile ? '54px' : '82px' }}>
            <Button
              type="primary"
              shape="round"
              onClick={() => setOpenType('person')}
              className="btnStyle"
              style={
                isMobile
                  ? {
                      height: '32px',
                      borderRadius: '40px',
                      fontSize: '12px',
                      lineHeight: '20px',
                      width: '130px',
                    }
                  : { height: '88px', width: '320px', borderRadius: '123.64px' }
              }
            >
              {data?.left_button}
            </Button>
            <Button
              shape="round"
              style={
                isMobile
                  ? {
                      border: '1.2px solid #234DA6',
                      height: '32px',
                      fontSize: '12px',
                      borderRadius: '40px',
                      lineHeight: '20px',
                      marginTop: '12px',
                      width: '130px',
                      marginLeft: '16px',
                    }
                  : {
                      marginLeft: '28px',
                      border: '4px solid #234DA6',
                      height: '88px',
                      width: '320px',
                      borderRadius: '123.64px',
                    }
              }
              className="btnStyle"
            >
              <a href="#video">{data?.right_button}</a>
            </Button>
          </div>
        </div>
      ) : (
        <Loading
          style={{ width: '100%', height: isMobile ? '336px' : '361px' }}
        />
      )}
      <div className={isMobile ? 'freeTrialMobile' : 'freeTrial'}>
        <img
          src={voice}
          alt="voice"
          style={{ display: isMobile ? 'none' : '' }}
          width={isMobile ? '140px' : '230px'}
          height={isMobile ? '138px' : '270px'}
        />
        <div
          style={isMobile ? {} : { paddingTop: '72px', paddingLeft: '24px' }}
        >
          <div
            style={
              isMobile
                ? {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage:
                      'linear-gradient(to bottom,transparent 21px ,#FEE8C3 0, #FEE8C3 31px,transparent 0)',
                  }
                : {
                    display: 'flex',
                    backgroundImage:
                      'linear-gradient(to bottom,transparent 50px ,#FEE8C3 0, #FEE8C3 78px,transparent 0)',
                  }
            }
          >
            <img
              src={smallVoice}
              alt="symbol"
              width={isMobile ? '16px' : '56px'}
              height={isMobile ? '16px' : '86px'}
            />
            <span className="spanTitle">{data?.free_trial_title}</span>
            <img
              style={{ marginLeft: isMobile ? '8px' : '24px' }}
              src={emotion}
              alt="symbol"
              width={isMobile ? '10px' : '33px'}
              height={isMobile ? '20px' : '66px'}
            />
          </div>
          <ul
            className="ul"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '12px',
              fontSize: isMobile ? '10px' : '22px',
              paddingInlineStart: '12px',
              fontFamily: 'ZenMaruGothic-light',
            }}
          >
            {data?.free_trial_content?.map((tt: any) => (
              <li key={tt.id}>{tt.value}</li>
            ))}
          </ul>
        </div>
        <div
          style={
            isMobile
              ? { display: 'flex', justifyContent: 'center' }
              : { display: 'flex', marginLeft: '52px', alignItems: 'center' }
          }
        >
          <Button
            type="primary"
            shape="round"
            onClick={() => window.open(IECE_USER_URL_REGISTER(language))}
            style={
              isMobile
                ? { fontSize: '12px', lineHeight: '20px' }
                : { width: '232px', height: '66px' }
            }
            className="btnStyle"
          >
            {data?.free_trial_button}
          </Button>
        </div>
      </div>
      <FunctionBlock />
      <Video title={blockTitle?.title_home_introduction_video} />
      <Cooperate />
      <VoiceOfUser
        title={blockTitle?.title_home_user_voice}
        childTitle={blockTitle?.user_voice_student}
        parentTitle={blockTitle?.user_voice_parent}
      />
      <News
        title={blockTitle?.title_home_news}
        isShowNull={blockTitle?.show_home_news === false}
      />
      {!!openType && (
        <CooperationForm
          type={openType}
          onCancel={() => setOpenType(undefined)}
        />
      )}
    </div>
  )
}

export default Introduce
