import React, { FC, useState, useEffect } from 'react'
import { Drawer, Divider } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'

import sideSvg from 'app/asset/side.svg'
import LanguageSelect from './LanguageSwitch'
import { getFooter } from 'app/pages/introduce/api'
import Language from 'app/container/language'
import { IECE_USER_URL_REGISTER, IECE_USER_URL_LOGIN } from 'app/utils'

const SideMenu: FC<{ items: any; isMainPage: boolean }> = ({
  items,
  isMainPage,
}) => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<any>()
  const { language } = Language.useContainer()
  const location = useLocation()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const isNewPage = location.pathname === '/news-detail'

  useEffect(() => {
    getFooter(language).then((res) => setData(res?.[0]?.attributes))
  }, [language])

  const link = data?.first.sort((a: any, b: any) => a.sort - b.sort)

  return (
    <span>
      <img
        src={sideSvg}
        width={24}
        height={24}
        alt=""
        onClick={() => setOpen(true)}
        style={{ cursor: 'pointer' }}
      />
      <Drawer
        placement={'right'}
        open={open}
        width={240}
        onClose={() => setOpen(false)}
        className="customSide"
        style={{ background: '#E6F4FE' }}
      >
        <LeftOutlined
          style={{ cursor: 'pointer', color: '#234DA6' }}
          onClick={() => setOpen(false)}
        />
        <div style={{ marginTop: '20px' }}>
          {isMainPage && (
            <>
              {items?.slice(0, 3).map((tt: any) => (
                <div
                  key={tt.href}
                  style={{
                    color: '#234DA6',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    padding: '4px',
                    marginTop: '8px',
                  }}
                >
                  <a
                    style={{ width: '180px' }}
                    href={tt.href}
                    onClick={() => setOpen(false)}
                  >
                    {tt.title}
                  </a>
                </div>
              ))}
              <Divider style={{ background: '#C1E1FF' }} />
            </>
          )}
          <div
            style={{
              color: '#234DA6',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              padding: '4px',
              marginTop: '20px',
            }}
          >
            <a
              onClick={() => {
                setOpen(false)
                window.open(IECE_USER_URL_REGISTER(language))
              }}
            >
              {formatMessage({ id: 'new_registration' })}
            </a>
            <Divider type="vertical" />
            <a
              onClick={() => {
                setOpen(false)
                window.open(IECE_USER_URL_LOGIN(language))
              }}
            >
              {formatMessage({ id: 'login' })}
            </a>
          </div>
          {!isNewPage && <LanguageSelect cb={() => setOpen(false)} />}
          <Divider style={{ background: '#C1E1FF' }} />
          {link?.map((tt: any) => {
            if (tt.show === false) {
              return null
            }
            return (
              <div
                key={tt.link_to}
                onClick={() => {
                  navigate(`/${tt.link_to}`)
                  setOpen(false)
                }}
                style={{
                  color: '#234DA6',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  padding: '4px',
                  marginTop: '20px',
                }}
              >
                <a
                  className={
                    location.pathname === `/${tt.link_to}` ? 'spanFocus' : ''
                  }
                  style={{ width: '180px' }}
                >
                  {tt.title}
                </a>
              </div>
            )
          })}
        </div>
      </Drawer>
    </span>
  )
}

export default SideMenu
