/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios'
import { merge } from 'lodash-es'

import { Options, RequestConfig } from './types'
import { getToken, getLanguage } from 'app/utils'

export default class Http {
  private checkResponse: (res: any) => boolean // 请求成功时检查接口状态
  private getResData: (res: any) => any // 获取接口中的数据
  private getResMsg: (res: any) => any // 获取接口中的msg
  private getResStatus: (res: any) => any // 获取接口中的状态码
  private tipFn: (msg: string) => void // 提示函数
  private beforeHook?: (config: RequestConfig) => any // 请求前的钩子函数
  private afterHook?: (res: any, isErr?: boolean) => any // 请求后的钩子函数 ⚠️ 接口取消后不走afterHook
  private errorHandler?: (statusCode: number, res?: any) => any // 根据状态码做错误处理 ⚠️ 接口取消后不走errorHandler
  private cancelDuplicated?: boolean // 是否取消重复请求

  private pendingAjax: Map<any, any> // 正在pending的请求
  private axios: AxiosInstance // axios 实例

  constructor(options: Options, axiosRequestConfig?: AxiosRequestConfig) {
    this.checkResponse = options.checkResponse || (() => true)
    this.getResData = options.getResData || ((res) => res)
    this.getResMsg = options.getResMsg || (() => '')
    this.getResStatus = options.getResStatus || (() => null)
    this.tipFn = options.tipFn || ((msg) => console.log(msg))
    this.beforeHook = options.beforeHook
    this.afterHook = options.afterHook
    this.errorHandler = options.errorHandler
    this.cancelDuplicated = options.cancelDuplicated

    this.pendingAjax = new Map()
    this.axios = axios.create(
      merge(
        {
          timeout: 150000,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        },
        axiosRequestConfig
      )
    )

    this.interceptors()
  }

  private interceptors(): void {
    this.axios.interceptors.request.use((config: any) => {
      this.removePendingAjax(config)
      this.addPendingAjax(config)

      const { disableBeforeHooks } = config.httpConfig || {}
      // 钩子函数
      if (this.beforeHook && !disableBeforeHooks) this.beforeHook(config)

      config.headers.Authorization = getToken()
      config.headers['X-Locale'] = getLanguage()

      return config
    })

    this.axios.interceptors.response.use(
      // 状态码为2xx时默认触发，除非axiosRequestConfig设置validateStatus
      (response) => {
        const config: RequestConfig = response.config || {}
        this.removePendingAjax(config)

        const { disableTip, disableAfterHooks } = config.httpConfig || {}
        // 钩子函数
        if (this.afterHook && !disableAfterHooks) this.afterHook(response)

        // 接口类型为下载时直接返回response
        if (config.responseType === 'blob') return response

        const data = response.data || {}
        // 数据验证通过，直接返回 getResData 结果
        if (this.checkResponse(data)) return this.getResData(data)
        // 数据验证未通过
        // 全局错误提示
        if (!disableTip) this.tipFn(this.getResMsg(data))
        // 错误码处理
        const status = this.getResStatus(data)

        if (this.errorHandler) this.errorHandler(status, data)

        return Promise.reject(data)
      },
      // 状态码不为2xx时默认触发，除非axiosRequestConfig设置validateStatus
      (error) => {
        if (axios.isCancel(error)) {
          return Promise.reject({
            status: 'cancel',
            errMsg: error.message,
          })
        }

        const config: RequestConfig = error.config || {}
        this.removePendingAjax(config)

        const { disableAfterHooks } = config.httpConfig || {}
        // 钩子函数
        if (this.afterHook && !disableAfterHooks) this.afterHook(error, true)

        const response = error.response
        // response === 'undefined'（跨域，超时）
        if (typeof response === 'undefined') {
          return Promise.reject({
            status: 'fail',
            errCode: -1,
            errMsg: 'response undefined',
          })
        }

        const data = {
          status: 'fail',
          // 处理认证问题
          errCode: response?.status,
          body: response?.data.body,
          errMsg: response?.statusText,
          config: config.data,
        }

        if (this.errorHandler) this.errorHandler(data.errCode, data)

        return Promise.reject(data)
      }
    )
  }

  // 添加正在pending的请求
  private addPendingAjax(config: RequestConfig): void {
    if (!this.cancelDuplicated || config.cancelToken) return

    const duplicatedKey = getDuplicatedKey(config)
    config.cancelToken = new axios.CancelToken((cancel) => {
      this.pendingAjax.set(duplicatedKey, cancel)
    })
  }
  // 删除pending中的重复请求
  private removePendingAjax(config: RequestConfig): void {
    if (!this.cancelDuplicated) return

    const duplicatedKey = getDuplicatedKey(config)
    // 如果当前key有pending中的请求
    if (this.pendingAjax.has(duplicatedKey)) {
      const cancel = this.pendingAjax.get(duplicatedKey)
      cancel(`取消请求：${duplicatedKey}`)
      this.pendingAjax.delete(duplicatedKey)
    }
  }

  // 取消并清空正在pending的请求
  clearPendingAjax() {
    this.pendingAjax.forEach((cancel, duplicatedKey) =>
      cancel(`取消请求：${duplicatedKey}`)
    )
    this.pendingAjax.clear()
  }

  get(url: string, params?: any, config: RequestConfig = {}): Promise<any> {
    return this.axios.get(url, { params, ...config })
  }
  post(url: string, params?: any, config?: RequestConfig): Promise<any> {
    return this.axios.post(url, params, config)
  }
  download(
    url: string,
    params?: any,
    method?: string,
    config?: RequestConfig
  ): Promise<any> {
    const handleResponse = (response: AxiosResponse) => {
      const data = response.data || {}
      const disableTip = config?.httpConfig?.disableTip

      // 后台返回 json 格式，说明接口下载失败
      if (data.type === 'application/json') {
        const promise = new Promise((_, reject) => {
          const fileReader = new FileReader()
          fileReader.onload = () => {
            let res = JSON.parse(fileReader.result as string)

            // 全局错误提示
            if (!disableTip) this.tipFn(this.getResMsg(res))
            // 错误码处理
            const status = this.getResStatus(res)
            if (this.errorHandler) this.errorHandler(status, res)

            reject(res)
          }
          fileReader.readAsText(data)
        })
        return promise
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([data]))
        const contentDisposition = decodeURIComponent(
          response.headers['content-disposition'] || ''
        )
        link.download =
          contentDisposition.split('filename=').pop() || 'anonymous'
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    }
    if (method === 'get') {
      return this.axios
        .get(url, { params, ...config, responseType: 'blob' })
        .then(handleResponse)
    }
    return this.axios
      .post(url, params, { ...config, responseType: 'blob' })
      .then(handleResponse)
  }
}

const getDuplicatedKey = (config: RequestConfig) => {
  const httpConfig = config.httpConfig || {}
  return httpConfig.duplicatedKey || `${config.method} ${config.url}`
}
