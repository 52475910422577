import React, { FC, useState } from 'react'
import { Button } from 'antd'
import { useIntl } from 'react-intl'

import Cooperate from 'app/pages/introduce/components/CooperationForm'
import useIsMobile from 'app/hooks'

const CooperateBlock: FC = () => {
  const isMobile = useIsMobile()
  const { formatMessage } = useIntl()
  const [openType, setOpenType] = useState<'person' | 'company'>()
  return (
    <div
      style={{
        width: '100%',
        background: '#E5F3FE',
        height: isMobile ? '160px' : '248px',
        marginTop: isMobile ? '48px' : '84px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        type="primary"
        shape="round"
        onClick={() => setOpenType('company')}
        className="btnStyle"
        style={
          isMobile
            ? {
                height: '32px',
                borderRadius: '40px',
                fontSize: '12px',
                lineHeight: '20px',
              }
            : { height: '88px', width: '320px', borderRadius: '123.64px' }
        }
      >
        {formatMessage({ id: 'contact_us' })}
      </Button>
      {openType && (
        <Cooperate
          type={openType}
          onCancel={() => setOpenType(undefined)}
          title={formatMessage({ id: 'contact_us' })}
        />
      )}
    </div>
  )
}

export default CooperateBlock
