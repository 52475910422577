import React, { FC } from 'react'

import MainMission from './MainMission'
import Content from './Content'
import TopNews from './topNews'
import Mode from './Mode'

const Top: FC = () => {
  return (
    <div>
      <MainMission />
      <Content />
      <TopNews />
      <Mode />
    </div>
  )
}

export default Top
