const TokenKey = '__icec_token'

const storage = localStorage

const saveToken = (token: string) => storage.setItem(TokenKey, token)

const getToken = () => storage.getItem(TokenKey)

const transfQueryParams = (href: string) => {
  const params = href.split('?')[1]
  let res: { [key: string]: string } = {}
  if (params) {
    params.split('&').forEach((ss) => {
      const [key, value] = ss.split('=')
      res[key] = decodeURIComponent(value)
    })
  }
  return res
}

const getImgUrl = (img?: any) => img?.data?.attributes?.url

const isEmail = (email: string) => {
  const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/
  return reg.test(email)
}

const isTel = (tel: string) => {
  const reg = /^(\+\d{1,3}\s?)?(\d{2,4}-?)?\d{3,4}-\d{4}$/
  return reg.test(tel)
}

export * from './constant'
export { saveToken, getToken, transfQueryParams, getImgUrl, isEmail, isTel }
