import React, { FC } from 'react'
import { Divider, Button } from 'antd'
import { useIntl } from 'react-intl'
import { IECE_USER_URL_LOGIN, IECE_USER_URL_REGISTER } from 'app/utils'
import Language from 'app/container/language'

const LangBtn: FC = () => {
  const { formatMessage } = useIntl()
  const { language } = Language.useContainer()
  return (
    <div
      style={{
        borderRadius: '100px',
        background: '#234DA6',
        marginLeft: '20px',
      }}
    >
      <Button
        style={{ color: '#fff' }}
        type="link"
        onClick={() => window.open(IECE_USER_URL_REGISTER(language))}
      >
        {formatMessage({ id: 'new_registration' })}
      </Button>
      <Divider
        type="vertical"
        style={{ marginInline: '0px', background: '#fff' }}
      />
      <Button
        type="link"
        onClick={() => window.open(IECE_USER_URL_LOGIN(language))}
        style={{ color: '#fff' }}
      >
        {formatMessage({ id: 'login' })}
      </Button>
    </div>
  )
}

export default LangBtn
