import React, { FC } from 'react'
import { Select } from 'antd'

import langIcon from 'app/asset/languageIcon.svg'
import { LanguageOptions } from 'app/utils'
import useIsMobile from 'app/hooks'
import Language from 'app/container/language'
import './index.less'

const LanguageSelect: FC<{ cb?: () => void }> = ({ cb }) => {
  const isMobile = useIsMobile()
  const { language, changeLanguage } = Language.useContainer()

  if (isMobile) {
    return (
      <Select
        options={LanguageOptions}
        bordered={false}
        onChange={(val) => {
          cb && cb()
          changeLanguage(val)
        }}
        value={language}
        className={'select-mobile'}
        style={{ color: '#234DA6', width: '86px' }}
      />
    )
  }
  return (
    <div
      style={{
        border: '1px solid #234DA6',
        borderRadius: '100px',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          background: '#234DA6',
          height: '32px',
          padding: '5px 8px 5px 12px',
        }}
      >
        <img src={langIcon} width={22} height={22} alt="lang-icon" />
      </div>

      <Select
        options={LanguageOptions}
        bordered={false}
        onChange={(val) => {
          cb && cb()
          changeLanguage(val)
        }}
        value={language}
        className="select"
        style={{ color: '#234DA6', width: '96px' }}
      />
    </div>
  )
}

export default LanguageSelect
