import React, { FC, Fragment, ReactNode, useEffect, useState } from 'react'
import { Button, Divider, Anchor, Affix } from 'antd'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import icon from 'app/asset/icon.svg'
import LanguageSelect from './LanguageSwitch'
import LangBtn from './LoginBtn'
import Footer from '../footer'
import './index.less'
import { getHeader } from 'app/pages/introduce/api'
import Language from 'app/container/language'
import useIsMobile from 'app/hooks'
import SideMenu from './SideMenu'

const Header: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [data, setData] = useState<any>([])
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()
  const [affixed, setAffixed] = useState(false)
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null)

  useEffect(() => {
    getHeader(language).then((res) => setData(res || []))
  }, [language])

  useEffect(() => {
    document.getElementById('main')!.scrollTop = 0
  }, [location.pathname])

  const items = data
    .sort((a: any, b: any) => a.attributes.sort - b.attributes.sort)
    .map((tt: any) => ({
      key: `#${tt.attributes.link_to}`,
      href: `#${tt.attributes.link_to}`,
      title: tt.attributes.title,
    }))

  const isMainPage = location.pathname === '/elearning'

  const isNewPage = location.pathname === '/news-detail'

  const menuData = items.slice(0, 3)

  return (
    <div
      id={'main'}
      style={{
        position: 'relative',
        height: isMobile ? '100dvh' : '100vh',
        background: isMainPage
          ? 'linear-gradient(to bottom, #EEF8FF 20%, #ffff 100%)'
          : '',
        overflowX: 'hidden',
      }}
      ref={setContainer}
    >
      <div
        className={isMainPage ? 'headerBlock' : 'otherBlock'}
        style={{ height: isMobile ? '580px' : '' }}
      >
        <Affix
          offsetTop={0}
          target={() => container}
          onChange={(affixed?: boolean) => {
            setAffixed(affixed || false)
          }}
        >
          <div
            style={{
              background: affixed ? '#FFF' : '',
              boxShadow: affixed
                ? '0px -6px 28px -16px rgba(94, 139, 220, 0.53)'
                : 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: isMobile ? '8px 30px' : '10px 80px',
                maxWidth: '1380px',
                margin: '0 auto',
              }}
            >
              <img
                width={isMobile ? 92 : 133}
                height={isMobile ? 44 : 64}
                // width={92}
                // height={44}
                src={icon}
                alt="icon"
                onClick={() => navigate('/elearning')}
                style={{ cursor: 'pointer' }}
              />
              {isMobile ? (
                <SideMenu items={items} isMainPage={isMainPage} />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    height: '32px',
                  }}
                >
                  {isMainPage ? (
                    <div>
                      {menuData.map((tt: any, index: number) => {
                        return (
                          <Fragment key={tt.title}>
                            <Button type="link">
                              <a href={tt.href}>{tt.title}</a>
                            </Button>
                            {index < menuData.length - 1 && (
                              <Divider
                                type="vertical"
                                style={{
                                  marginInline: '0px',
                                  background: '#234DA6',
                                }}
                              />
                            )}
                          </Fragment>
                        )
                      })}
                    </div>
                  ) : (
                    <div />
                  )}
                  {!isNewPage && <LanguageSelect />}
                  <LangBtn />
                </div>
              )}
            </div>
          </div>
        </Affix>
        <Outlet />
        <Footer />
      </div>
    </div>
  )
}

export default Header
