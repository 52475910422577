import { message } from 'antd'
import { Options } from './types'
import Http from '.'
import { AxiosRequestConfig } from 'axios'
import { CMSUrl } from 'app/utils'

const baseOption: Options = {
  checkResponse: (res) => !!res,
  getResData: (res) => res.data,
  getResMsg: (res) => res.msg || message.error,
  getResStatus: (res) => res.code,
  tipFn: message.error,
  errorHandler: (statusCode, res) => {
    if (statusCode === 401) {
      if (res.config) {
        try {
          const data = JSON.parse(res.config)
          if (data.redirectUri) {
            window.location.href =
              window.location.origin + '/login?type=redirect'
          }
        } catch (error) {
          window.location.href = window.location.origin + '/login'
        }
      } else {
        window.location.href = window.location.origin + '/login'
      }
    } else {
      message.error(res.errMsg)
    }
  },
  afterHook: (res, isErr) => {
    if (isErr) {
      const response = res.response
      if (response) {
        // const httpError = new Error(
        //   `${response.status} ${response.config && response.config.url}`
        // )
      }
    }
  },
}

const axiosRequestConfig: AxiosRequestConfig = {
  // baseURL: 'http://52.199.246.24:1337',
  baseURL: CMSUrl,
}

export const http = new Http(baseOption, axiosRequestConfig)

export const httpMeta = new Http(
  { ...baseOption, getResData: (res) => res },
  axiosRequestConfig
)

export const httpSubmit = new Http({
  ...baseOption,
  checkResponse: (res) => res.code === 200000,
})

export const cancelableHttp = new Http({
  ...baseOption,
  cancelDuplicated: false,
})
