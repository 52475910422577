import React, { FC, useState, useEffect } from 'react'
import { Button } from 'antd'

import { BlockTitle } from './FunctionBlock'
import parent from 'app/asset/parent.svg'
import school from 'app/asset/school.svg'
import company from 'app/asset/company.svg'
import { getHomePrice } from './api'
import Language from 'app/container/language'
import useIsMobile from 'app/hooks'

import CooperationForm from './components/CooperationForm'
import { IECE_USER_URL_REGISTER } from 'app/utils'

const Cooperate: FC = () => {
  const [data, setData] = useState<any>()
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()
  const [openType, setOpenType] = useState<'person' | 'company' | 'school'>()

  useEffect(() => {
    getHomePrice(language).then((res) => setData(res?.[0]?.attributes))
  }, [language])

  return (
    <div
      style={
        isMobile
          ? { width: '330px', margin: '0 auto 0', paddingTop: '60px' }
          : { margin: '0 auto', paddingTop: '84px' }
      }
      id="feePlan"
    >
      <BlockTitle text={data?.title || '--'} />
      <div
        style={
          isMobile
            ? {
                fontSize: '10px',
                textAlign: 'center',
                color: '#7E6F6D',
                marginBottom: '20px',
              }
            : {
                textAlign: 'center',
                margin: '48px 0',
                color: '#7E6F6D',
                fontSize: '24px',
              }
        }
      >
        {data?.subtitle}
      </div>
      <div
        style={isMobile ? {} : { display: 'flex', justifyContent: 'center' }}
      >
        <div
          style={
            isMobile
              ? {
                  height: '190px',
                  background: '#407BFF',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                }
              : {
                  height: '514px',
                  width: '488px',
                  borderRadius: '12px',
                  background: '#407BFF',
                  textAlign: 'center',
                  marginRight: '30px',
                }
          }
        >
          <img
            src={parent}
            alt="parent"
            width={isMobile ? 152 : 300}
            height={isMobile ? 102 : 180}
          />
          <div
            style={
              isMobile
                ? {
                    color: '#FFF',
                    fontSize: '12px',
                    textAlign: 'center',
                    marginLeft: '26px',
                    width: '100px',
                  }
                : { color: '#FFF', fontSize: '20px' }
            }
          >
            <div style={isMobile ? {} : { margin: '28px 0 24px 0' }}>
              {data?.price[0]?.title}
            </div>
            <div>
              {data?.price[0]?.price_child.map((tt: any, index: number) => {
                return (
                  <div
                    key={tt.id}
                    style={
                      isMobile
                        ? {
                            width: '100px',
                            padding: '4px 0',
                            fontSize: '12px',
                            borderBottom:
                              index === 2 ? '' : '1px solid #8CB0FF',
                          }
                        : {
                            padding: '12px 0',
                            borderBottom:
                              index === 2 ? '' : '2px solid #8CB0FF',
                            width: '304px',
                            margin: '0 auto',
                            lineHeight: '25px',
                          }
                    }
                  >
                    {tt.value}
                  </div>
                )
              })}
            </div>
            <div>
              <Button
                type="primary"
                shape="round"
                onClick={() => window.open(IECE_USER_URL_REGISTER(language))}
                style={{
                  marginTop: '24px',
                  height: isMobile ? 'auto' : '42px',
                  fontSize: isMobile ? 10 : '20px',
                  background: '#fff',
                  color: '#234DA6',
                }}
              >
                {data?.price[0]?.sub_title}
              </Button>
            </div>
          </div>
        </div>
        <div style={isMobile ? { display: 'flex' } : {}}>
          <div
            style={
              isMobile
                ? {
                    height: '126px',
                    width: 'calc(50% - 6px)',
                    textAlign: 'center',
                    marginTop: '12px',
                    paddingTop: '12px',
                    marginRight: '12px',
                    borderRadius: '8px',
                    background:
                      'linear-gradient(0deg, #E5F3FE 0%, #E5F3FE 100%), #FFF',
                  }
                : {
                    width: '488px',
                    padding: '27.5px 86px',
                    boxSizing: 'border-box',
                    borderRadius: '12px',
                    textAlign: 'center',
                    background:
                      'linear-gradient(0deg, #E5F3FE 0%, #E5F3FE 100%), #FFF',
                  }
            }
          >
            <img
              src={school}
              alt="school"
              width={isMobile ? 62 : 130}
              height={isMobile ? 42 : 96}
            />
            <div>
              <div
                style={
                  isMobile
                    ? {
                        color: '#234DA6',
                        fontSize: '12px',
                        lineHeight: '9px',
                        margin: '12px 0',
                      }
                    : {
                        margin: '7.5px 0 20px 0',
                        color: '#234DA6',
                        fontSize: '20px',
                        lineHeight: '28px',
                      }
                }
              >
                {data?.price[1]?.title}
              </div>
              <Button
                type="primary"
                shape="round"
                onClick={() => setOpenType('school')}
                style={
                  isMobile
                    ? { fontSize: '10px', height: 'auto' }
                    : {
                        height: '34px',
                        fontSize: '16px',
                      }
                }
              >
                {data?.price[1]?.sub_title}
              </Button>
            </div>
          </div>
          <div
            style={
              isMobile
                ? {
                    height: '126px',
                    width: 'calc(50% - 6px)',
                    textAlign: 'center',
                    marginTop: '12px',
                    paddingTop: '12px',
                    borderRadius: '8px',
                    background:
                      'linear-gradient(0deg, #E5F3FE 0%, #E5F3FE 100%), #FFF',
                  }
                : {
                    width: '488px',
                    padding: '27.5px 86px',
                    boxSizing: 'border-box',
                    borderRadius: '12px',
                    marginTop: '24px',
                    textAlign: 'center',
                    background:
                      'linear-gradient(0deg, #E5F3FE 0%, #E5F3FE 100%), #FFF',
                  }
            }
          >
            <img
              src={company}
              alt="company"
              width={isMobile ? 62 : 130}
              height={isMobile ? 42 : 96}
            />
            <div>
              <div
                style={
                  isMobile
                    ? {
                        color: '#234DA6',
                        fontSize: '12px',
                        lineHeight: '9px',
                        margin: '12px 0',
                      }
                    : {
                        margin: '7.5px 0 20px 0',
                        color: '#234DA6',
                        fontSize: '20px',
                        lineHeight: '28px',
                      }
                }
              >
                {data?.price[2]?.title}
              </div>
              <Button
                type="primary"
                shape="round"
                onClick={() => setOpenType('company')}
                style={
                  isMobile
                    ? { fontSize: '10px', height: 'auto' }
                    : {
                        height: '34px',
                        fontSize: '16px',
                      }
                }
              >
                {data?.price[2]?.sub_title}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {openType && (
        <CooperationForm
          onCancel={() => setOpenType(undefined)}
          type={openType}
        />
      )}
    </div>
  )
}

export default Cooperate
