import React, { FC, useState, useEffect } from 'react'
import { Space } from 'antd'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'

import icon from 'app/asset/icon.svg'
import whiteIcon from 'app/asset/whiteLogo.svg'
import './index.less'
import { getFooter } from 'app/pages/introduce/api'
import Language from 'app/container/language'
import useIsMobile from 'app/hooks'
import mobileLine from 'app/asset/mobileLine.svg'

const SpanStyle = { cursor: 'pointer' }

const Footer: FC = () => {
  const navigate = useNavigate()
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()

  const location = useLocation()

  const [data, setData] = useState<any>()

  useEffect(() => {
    getFooter(language).then((res) => setData(res?.[0]?.attributes))
  }, [language])

  const link = data?.first.sort((a: any, b: any) => a.sort - b.sort)
  const privacy = data?.second.sort((a: any, b: any) => a.sort - b.sort)

  return (
    <div
      style={{
        background: '#234DA6',
        padding: isMobile ? '24px 33px' : '39px 120px',
      }}
    >
      <div style={{ margin: '0 auto', maxWidth: '1380px' }}>
        <div
          style={
            isMobile
              ? { textAlign: 'center' }
              : { display: 'flex', justifyContent: 'space-between' }
          }
        >
          <img
            src={whiteIcon}
            alt="icon"
            style={
              isMobile
                ? { display: 'block', margin: '0 auto', marginBottom: '20px' }
                : {}
            }
            width={isMobile ? 60 : 110}
            height={isMobile ? 28 : 53}
          />
          <Space
            style={
              isMobile
                ? { fontSize: '12px', color: '#FFF' }
                : { fontSize: '16px', color: '#FFF' }
            }
            size={'large'}
          >
            {link?.map((tt: any) => {
              if (tt.show === false) {
                return null
              }
              return (
                <span
                  key={tt.link_to}
                  style={SpanStyle}
                  className={
                    location.pathname === `/${tt.link_to}` ? 'spanFocus' : ''
                  }
                  onClick={() => navigate(`/${tt.link_to}`)}
                >
                  {tt.title}
                </span>
              )
            })}
          </Space>
        </div>
        {isMobile ? (
          <>
            <img src={mobileLine} alt="" width={'100%'} />
            <div style={{ textAlign: 'center' }}>
              <Space
                style={{ fontSize: '10px', color: '#FFF', margin: '12px auto' }}
              >
                {privacy?.map((tt: any, index: number) => {
                  return (
                    <span
                      key={tt.id}
                      style={{
                        ...SpanStyle,
                        marginLeft: index === 1 ? '20px' : '0',
                      }}
                      className={
                        location.pathname === `/${tt.link_to}`
                          ? 'spanFocus'
                          : ''
                      }
                      onClick={() => window.open(`/${tt.link_to}`)}
                    >
                      {tt.title}
                    </span>
                  )
                })}
              </Space>
              <div style={{ fontSize: '10px', color: '#FFF' }}>
                {`ⓒ Copyright iCEC ${new Date().getFullYear()}`}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                height: '1px',
                background: '#FFF',
                margin: '48px 0 20px 0',
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ fontSize: '16px', color: '#FFF' }}>
                {`ⓒ Copyright iCEC ${new Date().getFullYear()}`}
              </span>
              <Space style={{ fontSize: '16px', color: '#FFF' }}>
                {privacy?.map((tt: any, index: number) => {
                  return (
                    <span
                      key={tt.id}
                      style={{
                        ...SpanStyle,
                        marginLeft: index === 1 ? '20px' : '0',
                      }}
                      className={
                        location.pathname === `/${tt.link_to}`
                          ? 'spanFocus'
                          : ''
                      }
                      onClick={() => window.open(`/${tt.link_to}`)}
                    >
                      {tt.title}
                    </span>
                  )
                })}
              </Space>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Footer
