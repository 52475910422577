import React, { FC, useEffect, useMemo, useState } from 'react'

import { getOverview } from './api'
import { getImgUrl } from 'app/utils'
import './index.less'
import { isEmail, isTel, isEnglish } from 'app/utils'
import Language from 'app/container/language'
import useIsMobile from 'app/hooks'
import SeoMet from 'app/components/helMet'

const Overview: FC = () => {
  const [data, setData] = useState<any>()
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()

  useEffect(() => {
    getOverview(language).then((res) => {
      setData(res[0]?.attributes)
    })
  }, [language])

  const labelWidth = useMemo(() => {
    if (isEnglish(data?.title)) {
      if (isMobile) {
        return 108
      }
      return 248
    } else {
      if (isMobile) {
        return 90
      }
      return 168
    }
  }, [isMobile, data])

  const isEnglishMobile = isEnglish(data?.title) && isMobile

  const _content = useMemo(() => {
    if (data) {
      return data.kv.map((tt: any) => ({ label: tt.label, value: tt.value }))
    }
    return []
  }, [data])

  return (
    <div
      className="companyBg"
      style={{
        backgroundImage: `url(${getImgUrl(data?.image)})`,
        backgroundColor: '#234DA6',
        height: isMobile ? 407 : 592,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SeoMet locale={language} type={'companies'} />
      <div
        style={{
          width: 'fit-content',
          margin: '0 auto',
          color: '#FFF',
        }}
      >
        <div
          style={
            isMobile
              ? {
                  textAlign: 'center',
                  fontSize: '16px',
                  marginBottom: '24px',
                  letterSpacing: '2.32px',
                }
              : {
                  textAlign: 'center',
                  fontSize: '40px',
                  fontWeight: 700,
                  marginBottom: '48px',
                  letterSpacing: '5.8px',
                }
          }
        >
          {data?.title}
        </div>
        {_content.map((tt: any) => (
          <div
            key={tt.label}
            style={
              isMobile
                ? {
                    marginBottom: isEnglishMobile ? '8px' : '20px',
                    fontSize: '12px',
                    lineHeight: '20px',
                    fontWeight: 400,
                  }
                : {
                    marginBottom: '26px',
                    fontSize: '28px',
                    lineHeight: '36px',
                    fontWeight: 400,
                  }
            }
          >
            <div
              style={{
                display: isEnglishMobile ? 'block' : 'inline-block',
                width: isEnglishMobile ? 'max-content' : labelWidth,
                textAlignLast: 'justify',
                fontWeight: isEnglishMobile ? 500 : 'normal',
              }}
            >
              {isEnglishMobile ? tt.label : `${tt.label}：`}
            </div>
            <div
              style={{ display: isEnglishMobile ? 'block' : 'inline-block' }}
            >
              {isEmail(tt.value) ? (
                <a
                  style={{ color: '#fff', textDecoration: 'none' }}
                  href={`mailto:${tt.value}`}
                >
                  {tt.value}
                </a>
              ) : isTel(tt.value) ? (
                <a
                  href={`tel:${tt.value}`}
                  style={{ color: '#fff', textDecoration: 'none' }}
                >
                  {tt.value}
                </a>
              ) : (
                tt.value
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Overview
