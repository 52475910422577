import { http } from 'app/http/instance'

export const getOverview = (locale: string) =>
  http.get('/api/company-mains', {
    populate: '*',
    locale,
  })

export const getTop = (locale: string) =>
  http.get('/api/company-tops', { populate: '*', locale })

export const getMember = (locale: string) =>
  http.get('/api/company-members', {
    'populate[0]': 'members',
    'populate[1]': 'members.avatar,members.resume',
    locale,
  })

export const getICEC = (locale: string) =>
  http.get('/api/company-icecs', {
    locale,
    'populate[0]': 'intro',
    'populate[1]': 'intro.image',
  })

export const getICECInfo = (locale: string) =>
  http.get('/api/company-abouts', {
    locale,
    populate: '*',
  })
