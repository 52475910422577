import React, { FC, useState, useEffect } from 'react'
import { Carousel } from 'antd'

import backImg from 'app/asset/backGround-top4.jpeg'
import { BlockTitle } from 'app/pages/introduce/FunctionBlock'
import Language from 'app/container/language'
import { getTopAdvantages } from './api'
import { getImgUrl } from 'app/utils'
import useIsMobile from 'app/hooks'
import mobileLine from 'app/asset/mobileLine2.svg'

const Seg: FC<{ data: any }> = ({ data }) => {
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <div>
        {data?.advantages
          ?.sort((a: any, b: any) => a.sort - b.sort)
          .map((tt: any, index: number) => {
            return (
              <div key={tt.id}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {index % 2 !== 0 && (
                    <img
                      src={getImgUrl(tt.logo)}
                      alt=""
                      width={100}
                      height={100}
                      loading="lazy"
                    />
                  )}
                  <div style={{ width: 'calc(100% - 130px)' }}>
                    <div
                      style={{
                        fontSize: '12px',
                        color: '#234DA6',
                        fontWeight: 700,
                        marginTop: '20px',
                      }}
                    >
                      {tt.subtitle || '名門校のリソース活用'}
                    </div>
                    <div
                      style={{
                        color: '#3863C1',
                        fontSize: '12px',
                        marginTop: '20px',
                        textAlign: 'left',
                        fontFamily: 'ZenMaruGothic-light',
                      }}
                    >
                      {tt.content}
                    </div>
                  </div>
                  {index % 2 === 0 && (
                    <img
                      src={getImgUrl(tt.logo)}
                      alt=""
                      width={100}
                      height={100}
                      loading="lazy"
                    />
                  )}
                </div>
                <div style={{ margin: '24px 0' }}>
                  {index !== data?.advantages.length - 1 && (
                    <img
                      src={mobileLine}
                      alt=""
                      width={'100%'}
                      loading="lazy"
                    />
                  )}
                </div>
              </div>
            )
          })}
      </div>
    )
  }
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {data?.advantages
          ?.sort((a: any, b: any) => a.sort - b.sort)
          .map((tt: any, index: number) => {
            return (
              <div key={tt.id}>
                <div
                  style={{
                    width: '288px',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    alignItems: 'center',
                    height: '440px',
                    // justifyContent: 'space-between',
                  }}
                >
                  <img
                    src={getImgUrl(tt.logo)}
                    alt=""
                    width={222}
                    height={222}
                    loading="lazy"
                  />
                  <div
                    style={{
                      fontSize: '20px',
                      color: '#234DA6',
                      fontWeight: 700,
                      marginTop: '30px',
                    }}
                  >
                    {tt.subtitle || '名門校のリソース活用'}
                  </div>
                  <div
                    style={{
                      color: '#3863C1',
                      fontSize: '14px',
                      marginTop: '20px',
                      textAlign: 'left',
                      fontFamily: 'ZenMaruGothic-light',
                    }}
                  >
                    {tt.content}
                  </div>
                </div>
                {/* {index !== 2 && (
                  <div
                    style={{
                      fontSize: '40px',
                      fontWeight: 700,
                      letterSpacing: '5.8px',
                      lineHeight: '222px',
                      color: '#AFC8FF',
                    }}
                  >
                    X
                  </div>
                )} */}
              </div>
            )
          })}
      </div>
    </div>
  )
}

const Content: FC = () => {
  const [data, setData] = useState<any>([])
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()

  useEffect(() => {
    getTopAdvantages(language).then((res) => setData(res))
  }, [language])

  return (
    <div
      style={
        isMobile
          ? {
              marginTop: '48px',
              padding: '24px 30px',
              backgroundImage: `url(${backImg})`,
            }
          : {
              marginTop: '124px',
              padding: '124px 120px',
              backgroundImage: `url(${backImg})`,
            }
      }
    >
      <BlockTitle text={data[0]?.attributes.title || '私たちの強み'} />
      <Carousel
        style={{
          maxWidth: '1200px',
          margin: isMobile ? '20px auto 0' : '84px auto 0',
        }}
        dotPosition={data.length > 1 ? 'right' : undefined}
        autoplay
        autoplaySpeed={3000}
      >
        {data
          ?.sort((a: any, b: any) => a.attributes.sort - b.attributes.sort)
          .map((tt: any) => (
            <Seg key={tt.id} data={tt.attributes} />
          ))}
      </Carousel>
    </div>
  )
}

export default Content
