import { http, httpSubmit } from 'app/http/instance'
import { FormUrl } from 'app/utils'

export const getStudentVoice = (locale: string) =>
  http.get('/api/students-comments', { populate: 'image', locale })

export const getParentVoice = (locale: string) =>
  http.get('/api/parent-comments', { populate: 'image', locale })

export const getHeader = (locale: string) =>
  http.get('/api/home-headers', { locale })

export const getMainBlock = (locale: string) =>
  http.get('/api/home-mains', {
    populate: '*',
    locale,
  })

export const getHomeFeatures = (locale: string) =>
  http.get('/api/home-features', {
    locale,
    'populate[0]': 'feature',
    'populate[1]': 'feature.list,feature.logo',
  })

export const getVideoSrc = (locale: string) =>
  http.get('/api/home-introduction-videos', {
    populate: '*',
    locale,
  })

export const getHomePrice = (locale: string) =>
  http.get('/api/home-prices', {
    locale,
    'populate[0]': 'price',
    'populate[1]': 'price.image,price.price_child',
  })

export const getFooter = (locale: string) =>
  http.get('/api/home-footers', { locale, populate: '*' })

export type ISubmitInfo = {
  name: string
  email: string
  phone: string
  content: string
  companyName?: string
  job?: string
  category: 1 | 2
}

export const submitInfo = (params: ISubmitInfo) =>
  httpSubmit.post(`${FormUrl}/api/v1/cooperation`, params)

export const getBlockTitle = (locale: string) =>
  http.get('/api/page-configs', {
    locale,
    populate: '*',
  })

export const getNews = (locale: string) =>
  http.get('/api/home-news', {
    locale,
    populate: '*',
  })
