import React, { FC, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { getSeo, ISEOType } from './api'

const SeoCom: FC<{ locale: string; type: ISEOType }> = ({ locale, type }) => {
  const [data, setData] = useState<any>()
  useEffect(() => {
    getSeo(locale, type).then((res) => setData(res?.[0]?.attributes.seo))
  }, [locale, type])
  return (
    <Helmet>
      <title>{data?.metaTitle}</title>
      <meta name=""></meta>
      <meta name="keywords" content={data?.keywords}></meta>
      <meta name="description" content={data?.metaDescription}></meta>
    </Helmet>
  )
}

export default SeoCom
