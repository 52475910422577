import { http, httpSubmit } from 'app/http/instance'

const UrlMap = {
  companies: '/api/seo-companies',
  homes: '/api/seo-homes',
  news: '/api/seo-news',
  tops: '/api/seo-tops',
}

export type ISEOType = keyof typeof UrlMap

export const getSeo = (locale: string, type: ISEOType) => {
  const url = UrlMap[type]
  return http.get(url, {
    populate: '*',
    locale,
  })
}
