import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BlockTitle } from './FunctionBlock'
import useIsMobile from 'app/hooks'
import Language from 'app/container/language'
import { getNews } from './api'
import { getImgUrl } from 'app/utils'

const Item: FC<{ hasNext: boolean; data: any }> = ({ hasNext, data }) => {
  const isMobile = useIsMobile()
  return (
    <div
      style={
        isMobile ? { margin: '0 30px' } : { width: '1200px', margin: '0 auto' }
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: isMobile ? '12px' : '40px',
          marginBottom: isMobile ? '24px' : '20px',
          borderBottom: hasNext ? '1px solid #B1B1B1' : 'none',
        }}
      >
        <div style={{ fontSize: isMobile ? '12px' : '20px' }}>
          <div
            style={{
              color: '#2B2525',
              fontWeight: 600,
            }}
          >
            {data?.name}
          </div>
          <div
            style={{
              fontWeight: 400,
              color: '#4D4D4D',
              fontFamily: 'ZenMaruGothic-light',
            }}
          >
            {data?.date}
          </div>
        </div>
        <img
          style={{
            borderRadius: isMobile ? '4px' : '8px',
            marginLeft: isMobile ? '20px' : 0,
          }}
          src={getImgUrl(data?.image)}
          alt="news"
          width={isMobile ? 110 : 227}
          height={isMobile ? 82 : 170}
        />
      </div>
    </div>
  )
}

const News: FC<{ title: string; isShowNull: boolean }> = ({
  title,
  isShowNull,
}) => {
  const isMobile = useIsMobile()
  const { language } = Language.useContainer()
  const [data, setData] = useState<any>()
  const naviage = useNavigate()

  useEffect(() => {
    getNews(language).then((res) => {
      setData(res)
    })
  }, [language])

  if (isShowNull) {
    return null
  }

  const showData = data?.sort(
    (a: any, b: any) => a.attributes.sort - b.attributes.sort
  )

  return (
    <div style={{ margin: isMobile ? '48px 0 20px 0' : '80px 0' }}>
      <BlockTitle text={title} />
      {/* <div style={{ marginTop: isMobile ? '' : '30px', textAlign: 'center' }}>
        coming soon
      </div> */}
      <div style={{ marginTop: isMobile ? '20px' : '30px' }}>
        {showData?.map((tt: any, index: number) => {
          return (
            <Item
              key={tt}
              hasNext={index !== showData.length - 1}
              data={tt.attributes}
            />
          )
        })}
      </div>

      <div
        style={
          isMobile
            ? {
                display: 'flex',
                justifyContent: 'flex-end',
                color: '#234DA6',
                margin: '0 30px',
                fontSize: '12px',
              }
            : {
                display: 'flex',
                justifyContent: 'flex-end',
                width: '1200px',
                color: '#234DA6',
                margin: '0 auto',
                fontSize: '20px',
              }
        }
      >
        <a style={{ cursor: 'pointer' }} onClick={() => naviage('/news')}>{`${
          showData?.[0]?.attributes.more_title || ''
        } >`}</a>
      </div>
    </div>
  )
}

export default News
