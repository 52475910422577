import React, { FC, useEffect, useState } from 'react'

import { BlockTitle } from 'app/pages/introduce/FunctionBlock'
import { getTop } from './api'
import Language from 'app/container/language'
import useIsMobile from 'app/hooks'
import { getImgUrl } from 'app/utils'

const TotalMessage: FC = () => {
  const [data, setData] = useState<any>()
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()

  useEffect(() => {
    getTop(language).then((res) => {
      setData(res)
    })
  }, [language])

  const showData = data?.sort(
    (a: any, b: any) => a.attributes.sort - b.attributes.sort
  )

  return (
    <div
      style={
        isMobile
          ? { margin: '48px 30px 0' }
          : {
              width: '1200px',
              margin: '0 auto',
              marginTop: '84px',
            }
      }
    >
      <BlockTitle
        style={{ textAlign: 'left' }}
        text={data?.[0]?.attributes['key_introduction_title'] || 'TOP MESSAGE'}
      />
      {showData?.slice(0, 2).map((tt: any) => {
        return <Message data={tt.attributes} key={tt.id} />
      })}
      <div style={{ marginTop: '24px', fontSize: '22px' }}>
        <div style={{ fontSize: isMobile ? '12px' : '26px' }}>
          {showData?.[2]?.attributes.title}
        </div>
        <div
          style={{
            fontSize: isMobile ? '12px' : '22px',
            lineHeight: isMobile ? '20px' : '34px',
            textAlign: 'left',
            fontFamily: 'ZenMaruGothic-light',
          }}
          dangerouslySetInnerHTML={{
            __html: showData?.[2]?.attributes['content']?.replaceAll(
              '\n',
              '<br />'
            ),
          }}
        />
      </div>
    </div>
  )
}

const Message: FC<{ data: any }> = ({ data }) => {
  const isMobile = useIsMobile()

  return (
    <div>
      {isMobile ? (
        <div style={{ marginTop: '20px' }}>
          <div
            style={{
              width: '100%',
              backgroundSize: 'cover',
              background: '#D9D9D9',
              borderRadius: '5px',
              overflow: 'hidden',
              backgroundImage: `url(${getImgUrl(
                data?.key_introduction_image
              )})`,
              aspectRatio: '1/1',
            }}
          ></div>
          <div
            style={{
              fontSize: '12px',
              lineHeight: '20px',
              textAlign: 'left',
              marginTop: '20px',
              fontFamily: 'ZenMaruGothic-light',
            }}
          >
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: data?.['key_introduction_content']?.replaceAll(
                  '\n',
                  '<br />'
                ),
              }}
            ></div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ width: 'fit-content', display: 'inline-block' }}>
                <div>{data?.company_name}</div>
                <div>{data?.leader_name}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: '48px', display: 'flex', fontSize: '22px' }}>
          <div
            style={{
              width: '360px',
              background: '#D9D9D9',
              borderRadius: '8px',
              height: '360px',
              marginRight: '24px',
              overflow: 'hidden',
            }}
          >
            <img
              src={getImgUrl(data?.key_introduction_image)}
              width={'100%'}
              height={'100%'}
              alt=""
            />
          </div>
          <div
            style={{
              width: 'calc(100% - 384px)',
              lineHeight: '34px',
              textAlign: 'left',
              fontFamily: 'ZenMaruGothic-light',
            }}
          >
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: data?.['key_introduction_content']?.replaceAll(
                  '\n',
                  '<br />'
                ),
              }}
            ></div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ width: 'fit-content', display: 'inline-block' }}>
                <div>{data?.company_name}</div>
                <div>{data?.leader_name}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TotalMessage
