import React, { FC, useCallback, useState } from 'react'
import { Form, Input, Modal, Button, message } from 'antd'
import { useIntl } from 'react-intl'
import useIsMobile from 'app/hooks'

import Language from 'app/container/language'
import { submitInfo } from '../api'
import './index.less'

const FormItem = Form.Item

const BasicInfo: FC<{
  onCancel: () => void
  title?: string
  type: 'person' | 'company' | 'school'
}> = ({ onCancel, type, title }) => {
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState(false)
  const { formatMessage } = useIntl()
  const isMobile = useIsMobile()
  const { language } = Language.useContainer()

  const onFinish = useCallback(
    (values: any) => {
      setSubmitting(true)
      const categoryConfig = {
        person: 1,
        company: 2,
        school: 3,
      }
      submitInfo({ ...values, category: categoryConfig[type] })
        .then((res) => {
          if (res) {
            message.success(formatMessage({ id: 'submit_success' }))
            onCancel()
          }
        })
        .finally(() => setSubmitting(false))
    },
    [formatMessage, onCancel, type]
  )

  const textPlace = useCallback(
    (a: string, b: string) => {
      if (language === 'ja-JP') {
        return b + ' ' + a
      }
      return a + ' ' + b
    },
    [language]
  )

  return (
    <Modal
      open
      width={600}
      footer={null}
      onCancel={onCancel}
      style={{ padding: '' }}
    >
      <div style={{ margin: isMobile ? '0' : '20px 36px' }}>
        <div
          style={{
            fontSize: '16px',
            fontWeight: 700,
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          {title
            ? title
            : formatMessage({
                id: `${type}_cooperation`,
              })}
        </div>
        <Form
          className={isMobile ? 'mobileForm' : ''}
          form={form}
          labelCol={{ span: 8 }}
          onFinish={onFinish}
        >
          <FormItem
            name={'name'}
            label={formatMessage({ id: 'user_name' })}
            required
            rules={[
              {
                required: true,
                message: textPlace(
                  formatMessage({ id: 'please_input' }),
                  formatMessage({ id: 'user_name' })
                ),
              },
            ]}
          >
            <Input
              placeholder={textPlace(
                formatMessage({ id: 'please_input' }),
                formatMessage({ id: 'user_name' })
              )}
            />
          </FormItem>
          <FormItem
            name={'email'}
            label={formatMessage({ id: 'contact_email' })}
            required
            rules={[
              {
                required: true,
                message: textPlace(
                  formatMessage({ id: 'please_input' }),
                  formatMessage({ id: 'contact_email' })
                ),
              },
            ]}
          >
            <Input
              placeholder={textPlace(
                formatMessage({ id: 'please_input' }),
                formatMessage({ id: 'contact_email' })
              )}
            />
          </FormItem>
          <FormItem
            name={'phone'}
            label={formatMessage({ id: 'contact_phone' })}
            required
            rules={[
              {
                required: true,
                message: textPlace(
                  formatMessage({ id: 'please_input' }),
                  formatMessage({ id: 'contact_phone' })
                ),
              },
            ]}
          >
            <Input
              placeholder={textPlace(
                formatMessage({ id: 'please_input' }),
                formatMessage({ id: 'contact_phone' })
              )}
            />
          </FormItem>
          {type !== 'person' && (
            <>
              <FormItem
                name={'companyName'}
                label={formatMessage({ id: `${type}_name` })}
                required
                rules={[
                  {
                    required: true,
                    message: textPlace(
                      formatMessage({ id: 'please_input' }),
                      formatMessage({ id: `${type}_name` })
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={textPlace(
                    formatMessage({ id: 'please_input' }),
                    formatMessage({ id: `${type}_name` })
                  )}
                />
              </FormItem>
              <FormItem
                name={'job'}
                label={formatMessage({ id: 'position' })}
                required
                rules={[
                  {
                    required: true,
                    message: textPlace(
                      formatMessage({ id: 'please_input' }),
                      formatMessage({ id: 'position' })
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={textPlace(
                    formatMessage({ id: 'please_input' }),
                    formatMessage({ id: 'position' })
                  )}
                />
              </FormItem>
            </>
          )}
          <FormItem
            name={'content'}
            label={formatMessage({ id: 'contact_content' })}
            required
            rules={[
              {
                required: true,
                message: textPlace(
                  formatMessage({ id: 'please_input' }),
                  formatMessage({ id: 'contact_content' })
                ),
              },
            ]}
          >
            <Input.TextArea
              placeholder={textPlace(
                formatMessage({ id: 'please_input' }),
                formatMessage({ id: 'contact_content' })
              )}
            />
          </FormItem>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <Button
              style={{ width: '100%' }}
              loading={submitting}
              type="primary"
              htmlType="submit"
            >
              {formatMessage({ id: 'submit' })}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default BasicInfo
