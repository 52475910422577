import React, { FC, useRef, useState, useEffect } from 'react'
import { Carousel } from 'antd'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'

// import missionImg from 'app/asset/mission.png'
import missionImg from 'app/asset/backGround-top-1.png'
import Language from 'app/container/language'
import { getImgUrl, isEnglish } from 'app/utils'
import { getTopBanner } from './api'
import useIsMobile from 'app/hooks'
import SeoMet from 'app/components/helMet'

const ImgContent: FC<{ data: any }> = ({ data }) => {
  const isMobile = useIsMobile()

  const is_en = isEnglish(data?.center_ball)

  return (
    <div
      style={
        isMobile
          ? {
              backgroundImage: `url(${getImgUrl(data.image)})`,
              height: '440px',
              margin: '0 30px',
              padding: is_en ? '20px 24px' : '42px 24px',
              boxSizing: 'border-box',
              borderRadius: '8px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }
          : {
              backgroundImage: `url(${getImgUrl(data.image)})`,
              width: '1200px',
              height: '580px',
              margin: '0 auto',
              padding: '41px 48px',
              borderRadius: '24px',
              boxSizing: 'border-box',
            }
      }
    >
      <div style={isMobile ? {} : { display: 'flex' }}>
        <div
          style={{
            marginTop: isMobile ? '' : is_en ? '46px' : '140px',
          }}
        >
          <div
            style={
              isMobile
                ? {
                    color: '#234DA6',
                    fontWeight: 700,
                    fontSize: '28px',
                    lineHeight: is_en ? '32px' : '36px',
                    textAlign: 'center',
                  }
                : {
                    color: '#234DA6',
                    fontWeight: 700,
                    fontSize: '54px',
                    lineHeight: '77.76px',
                  }
            }
          >
            {data?.title}
          </div>
          <div
            style={
              isMobile
                ? {
                    color: '#234DA6',
                    lineHeight: '18px',
                    fontSize: '16px',
                    marginTop: '16px',
                    textAlign: 'center',
                  }
                : {
                    color: '#234DA6',
                    lineHeight: '40px',
                    fontSize: '32px',
                    marginTop: is_en ? '12px' : '24px',
                  }
            }
          >
            {data?.content}
          </div>
        </div>
        <div
          style={
            isMobile
              ? {
                  position: 'relative',
                  width: 'fit-content',
                  margin: '0 auto',
                  paddingRight: '16px',
                  display: data?.show_ball ? 'block' : 'none',
                }
              : {
                  position: 'relative',
                  display: data?.show_ball ? 'block' : 'none',
                }
          }
        >
          <img
            src={missionImg}
            alt="missionImg"
            loading="lazy"
            style={
              isMobile ? { margin: is_en ? '18px auto' : '28px auto' } : {}
            }
            width={isMobile ? (is_en ? 236 : 268) : 576}
            height={isMobile ? (is_en ? 216 : 242) : 526}
          />
          {isMobile ? (
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <div
                style={{
                  color: '#fff',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  textAlign: 'center',
                  width: '129px',
                  height: '129px',
                  position: 'absolute',
                  left: is_en ? '62px' : '82px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '111px',
                    fontSize: is_en ? 10 : 18,
                    lineHeight: is_en ? '14px' : '24px',
                  }}
                >
                  {data?.center_ball}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  color: '#fff',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  width: '129px',
                  height: '129px',
                  position: 'absolute',
                  top: is_en ? '78px' : '92px',
                  left: is_en ? '16px' : '23px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    fontSize: is_en ? 10 : 18,
                    lineHeight: is_en ? '14px' : '24px',
                  }}
                >
                  {data?.left_ball}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  color: '#fff',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  width: '129px',
                  height: '129px',
                  position: 'absolute',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: is_en ? '78px' : '92px',
                  left: is_en ? '132px' : '146px',
                }}
              >
                <div
                  style={{
                    width: '84px',
                    fontSize: is_en ? 10 : 18,
                    lineHeight: is_en ? '14px' : '24px',
                  }}
                >
                  {data?.right_ball}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <div
                style={{
                  color: '#fff',
                  fontSize: '28px',
                  fontWeight: 700,
                  lineHeight: '36px',
                  textAlign: 'center',
                  width: '268px',
                  height: '268px',
                  position: 'absolute',
                  left: '179px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ fontSize: is_en ? 26 : 28 }}>
                  {data?.center_ball}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  color: '#fff',
                  fontSize: '28px',
                  fontWeight: 700,
                  lineHeight: '36px',
                  width: '268px',
                  height: '268px',
                  position: 'absolute',
                  top: '215px',
                  left: '57px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ fontSize: is_en ? 26 : 28 }}>
                  {data?.left_ball}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  color: '#fff',
                  fontSize: '28px',
                  fontWeight: 700,
                  lineHeight: '36px',
                  width: '268px',
                  height: '268px',
                  position: 'absolute',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: '215px',
                  left: '299px',
                }}
              >
                <div
                  style={{
                    width: is_en ? 136 : 112,
                    fontSize: is_en ? 26 : 28,
                  }}
                >
                  {data?.right_ball}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const MainMission: FC = () => {
  const [data, setData] = useState<any>([])
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()

  useEffect(() => {
    getTopBanner(language).then((res) => setData(res))
  }, [language])
  const sliderRef = useRef<any>()

  return (
    <div
      style={{
        position: 'relative',
        margin: '24px auto 0',
        maxWidth: '1380px',
        width: '100%',
      }}
    >
      <SeoMet locale={language} type={'tops'} />
      <Carousel ref={sliderRef} slidesToShow={1}>
        {data
          ?.sort((a: any, b: any) => a.attributes.sort - b.attributes.sort)
          .map((tt: any) => (
            <ImgContent key={tt.id} data={tt?.attributes} />
          ))}
      </Carousel>
      {!isMobile && (
        <>
          <LeftCircleOutlined
            onClick={() => {
              sliderRef.current?.prev()
            }}
            style={{
              position: 'absolute',
              left: 'calc(25% - 300px)',
              top: '270px',
              fontSize: '40px',
              cursor: 'pointer',
              color: 'rgb(172 167 167)',
            }}
          />
          <RightCircleOutlined
            onClick={() => sliderRef.current?.next()}
            style={{
              position: 'absolute',
              right: 'calc(25% - 300px)',
              top: '270px',
              fontSize: '40px',
              cursor: 'pointer',
              color: 'rgb(172 167 167)',
            }}
          />
        </>
      )}
    </div>
  )
}

export default MainMission
