import React, { FC, useRef, useEffect, useState, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Carousel } from 'antd'

import { BlockTitle } from './FunctionBlock'
import start from 'app/asset/start.svg'
import halfStart from 'app/asset/half_start.svg'
import bgCircle from 'app/asset/bg_circle.svg'
import person from 'app/asset/person_icon.png'
// import next from 'app/asset/next.svg'
// import last from 'app/asset/last.svg'
import next from 'app/asset/right_next.png'
import last from 'app/asset/left_last.png'
import useIsMobile from 'app/hooks'
import Language from 'app/container/language'
import { getImgUrl } from 'app/utils'

import { getStudentVoice, getParentVoice } from './api'

const Card: FC<{ data: any }> = ({ data }) => {
  const isMobile = useIsMobile()

  const startList = useMemo(() => {
    if (!data.star) {
      return []
    }
    const inter = data.star % 1
    return inter > 0
      ? new Array(Math.floor(data.star)).fill(1).concat(inter)
      : new Array(Math.floor(data.star)).fill(1)
  }, [data.star])
  return (
    <div
      style={
        isMobile
          ? {
              width: '220px',
              height: '358px',
              background: '#fff',
              position: 'relative',
              borderRadius: '4px',
              padding: '12px 16px',
            }
          : {
              width: '490px',
              height: '468px',
              borderRadius: '16px',
              background: '#fff',
              position: 'relative',
            }
      }
    >
      <img
        src={bgCircle}
        alt="circle"
        width={isMobile ? 150 : 268}
        height={isMobile ? 138 : 274}
        style={{ position: 'absolute', right: 0, top: 0 }}
      />
      <div
        style={{
          position: 'relative',
          zIndex: 99,
          padding: isMobile ? '' : '43px 46px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <img
            src={getImgUrl(data?.image)}
            alt="person"
            width={60}
            height={60}
            loading="lazy"
          />
          <div
            style={
              isMobile
                ? { fontSize: '10px', marginLeft: '8px' }
                : { marginLeft: '15px', fontSize: '16px', fontWeight: 700 }
            }
          >
            {/* <div>{data?.name}</div> */}
            <div style={{ color: '#7E6F6D' }}>{data?.age_info}</div>
            <div style={{ color: '#7E6F6D' }}>{data?.family}</div>
          </div>
        </div>
        <div
          style={{
            margin: isMobile ? '12px 0' : '18px 0',
            color: '#D6A936',
            fontWeight: 900,
            fontSize: isMobile ? '12px' : '16px',
            letterSpacing: '2.32px',
          }}
          className="textOverflow"
        >
          {data?.title}
        </div>
        <div
          style={{ marginBottom: isMobile ? '12px' : '18px', display: 'flex' }}
        >
          {startList.map((tt, index) => (
            <img
              key={index}
              src={tt === 1 ? start : halfStart}
              alt="start"
              width={isMobile ? 14 : 24}
              height={isMobile ? 14 : 24}
              style={{ marginRight: '6px' }}
            />
          ))}
        </div>
        <div
          style={{
            color: '#3360B6',
            fontWeight: 700,
            lineHeight: isMobile ? '20px' : '26px',
            fontSize: isMobile ? '12px' : '16px',
          }}
        >
          {data?.content}
        </div>
      </div>
    </div>
  )
}

const VoiceOfUser: FC<{
  title: string
  childTitle: string
  parentTitle: string
}> = ({ title, childTitle, parentTitle }) => {
  const sliderChildRef = useRef<any>()
  const sliderParentRef = useRef<any>()
  const isMobile = useIsMobile()
  const { language } = Language.useContainer()

  const [childData, setChildData] = useState<any>([])
  const [parentData, setParentData] = useState<any>([])

  useEffect(() => {
    getStudentVoice(language).then((res) => setChildData(res))
  }, [language])

  useEffect(() => {
    getParentVoice(language).then((res) => setParentData(res))
  }, [language])

  const iconWH = isMobile ? 48 : 96

  return (
    <div
      style={{
        margin: '0 auto',
        paddingTop: isMobile ? 68 : 84,
      }}
      id="userVoice"
    >
      <BlockTitle text={title} />
      <div
        style={
          isMobile
            ? {
                padding: '24px 0 24px 30px',
                background: '#D4E9F9',
                marginTop: '20px',
              }
            : {
                padding: '60px 0 24px 120px',
                background: '#D4E9F9',
                marginTop: '60px',
              }
        }
      >
        <div
          style={{
            position: 'relative',
            margin: isMobile ? '20px auto 0' : '60px auto 0',
            maxWidth: isMobile ? '360px' : '1380px',
          }}
        >
          <span
            style={
              isMobile
                ? {
                    display: 'block',
                    fontSize: '12px',
                    fontWeight: 700,
                    color: '#3360B6',
                  }
                : { color: '#3360B6', fontWeight: 700, fontSize: '24px' }
            }
          >
            {childTitle}
          </span>
          <Carousel
            ref={sliderChildRef}
            dots={false}
            slidesToShow={isMobile ? 1.1 : 2}
            slidesToScroll={isMobile ? 1 : 2}
            infinite={isMobile ? false : true}
            centerPadding={isMobile ? '25px' : '0px'}
            centerMode={isMobile ? true : false}
            style={
              isMobile
                ? { marginTop: '12px', position: 'relative' }
                : {
                    position: 'relative',
                    top: '-36px',
                    left: '170px',
                    width: '1080px',
                  }
            }
            arrows
          >
            {childData
              .sort((a: any, b: any) => a?.attributes.sort - b?.attributes.sort)
              .map((data: any) => (
                <Card key={data.id} data={data.attributes} />
              ))}
          </Carousel>
          <img
            src={next}
            alt="next-icon"
            height={iconWH}
            width={iconWH}
            onClick={() => {
              sliderChildRef.current?.next()
            }}
            style={{
              position: 'absolute',
              top: '200px',
              right: '24px',
              cursor: 'pointer',
            }}
          />
          <img
            src={last}
            alt="next-icon"
            height={iconWH}
            width={iconWH}
            onClick={() => {
              sliderChildRef.current?.prev()
            }}
            style={{
              position: 'absolute',
              top: '200px',
              left: '24px',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
      <div
        style={
          isMobile
            ? {
                padding: '24px 0 24px 30px',
                background: '#6192FD',
              }
            : {
                padding: '60px 0 24px 120px',
                background: '#6192FD',
              }
        }
      >
        <div
          style={{
            position: 'relative',
            margin: isMobile ? '20px auto 0' : '60px auto 0',
            maxWidth: isMobile ? '360px' : '1380px',
          }}
        >
          <span
            style={
              isMobile
                ? {
                    display: 'block',
                    fontSize: '12px',
                    fontWeight: 700,
                    color: '#3360B6',
                  }
                : { color: '#3360B6', fontWeight: 700, fontSize: '24px' }
            }
          >
            {parentTitle}
          </span>
          <Carousel
            ref={sliderParentRef}
            dots={false}
            slidesToShow={isMobile ? 1.1 : 2}
            infinite={isMobile ? false : true}
            slidesToScroll={isMobile ? 1 : 2}
            centerPadding={isMobile ? '25px' : '0px'}
            centerMode={isMobile ? true : false}
            style={
              isMobile
                ? { marginTop: '12px', position: 'relative' }
                : {
                    position: 'relative',
                    top: '-36px',
                    left: '170px',
                    width: '1080px',
                  }
            }
            arrows
          >
            {parentData
              .sort((a: any, b: any) => a?.attributes.sort - b?.attributes.sort)
              .map((data: any) => (
                <Card key={data.id} data={data.attributes} />
              ))}
          </Carousel>
          <img
            src={next}
            alt="next-icon"
            height={iconWH}
            width={iconWH}
            onClick={() => {
              sliderParentRef.current?.next()
            }}
            style={{
              position: 'absolute',
              top: '200px',
              right: '24px',
              cursor: 'pointer',
            }}
          />
          <img
            src={last}
            alt="next-icon"
            height={iconWH}
            width={iconWH}
            onClick={() => {
              sliderParentRef.current?.prev()
            }}
            style={{
              position: 'absolute',
              top: '200px',
              left: '24px',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default VoiceOfUser
