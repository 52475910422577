import React, { FC } from 'react'

import Overview from './Overview'
import Message from './Message'
// import Member from './Member'
import About from './AboutICEC'
import Cooperate from './Cooperation'

const Company: FC = () => {
  return (
    <div>
      <Overview />
      <Message />
      {/* <Member /> */}
      <About />
      <Cooperate />
    </div>
  )
}

export default Company
