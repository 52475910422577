import { http } from 'app/http/instance'

export const getTopBanner = (locale: string) =>
  http.get('/api/top-banners', {
    locale,
    'populate[0]': 'image',
  })

export const getTopService = (locale: string) =>
  http.get('/api/top-services', {
    locale,
    'populate[0]': 'service',
    'populate[1]': 'service.image',
  })

export const getTopNews = (locale: string) =>
  http.get('/api/top-news', {
    locale,
    'populate[0]': 'news',
    populate: 'news.image',
  })

export const getTopAdvantages = (locale: string) =>
  http.get('/api/top-advantages', {
    locale,
    'populate[0]': 'advantages',
    'populate[1]': 'advantages.logo',
  })
