import React, { FC, useState, useEffect, CSSProperties } from 'react'
import { useIntl } from 'react-intl'

import liIcon from 'app/asset/li-icon.svg'
import Language from 'app/container/language'
import { getHomeFeatures } from './api'
import useIsMobile from 'app/hooks'
import { getImgUrl } from 'app/utils'
import BrDiv from 'app/components/BrDiv'

const UlLi: FC<{ texts: string[] }> = ({ texts }) => {
  const isMobile = useIsMobile()
  return (
    <div
      style={
        isMobile
          ? { marginTop: '12px', fontSize: '12px', width: '190px' }
          : { marginTop: '40px', padding: '0 22px' }
      }
    >
      {texts.map((text) => {
        return (
          <div
            key={text}
            style={{
              display: 'flex',
              alignItems: 'baseline',
              marginBottom: '20px',
            }}
          >
            <img
              src={liIcon}
              alt="icon"
              width={isMobile ? 6 : 12}
              height={isMobile ? 6 : 12}
            />
            <BrDiv
              style={{
                marginLeft: '12px',
                color: '#7E6F6D',
                // fontFamily: 'ZenMaruGothic-light',
              }}
              text={text}
            ></BrDiv>
          </div>
        )
      })}
    </div>
  )
}

export const BlockTitle: FC<{
  style?: CSSProperties
  textId?: string
  text?: string
}> = ({ textId, text, style = {} }) => {
  const { formatMessage } = useIntl()
  const isMobile = useIsMobile()
  return (
    <div style={{ textAlign: 'center', ...style }}>
      <span
        className="blockTitle"
        style={{ fontSize: isMobile ? '16px' : '40px' }}
      >
        {textId ? formatMessage({ id: textId }) : text}
      </span>
    </div>
  )
}

const FunctionBlock: FC = () => {
  const [data, setData] = useState<any>([])
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()

  useEffect(() => {
    getHomeFeatures(language).then((res) => setData(res?.[0]?.attributes))
  }, [language])

  return (
    <div
      style={
        isMobile
          ? { paddingTop: '60px' }
          : { margin: '0px auto 80px', maxWidth: '1200px', paddingTop: '80px' }
      }
      id="uniqueFunctions"
    >
      <BlockTitle text={data?.title || '--'} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginTop: isMobile ? '20px' : '60px',
        }}
      >
        <div
          style={
            isMobile
              ? {}
              : {
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-around',
                }
          }
        >
          {data?.feature
            ?.sort((a: any, b: any) => a.sort - b.sort)
            .map((tt: any, index: number) => {
              if (isMobile) {
                if (index % 2 !== 0) {
                  return (
                    <div
                      key={tt.id}
                      style={{
                        margin: '0 32px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{ position: 'relative', marginRight: '24px' }}
                      >
                        <img
                          width={105}
                          height={105}
                          src={getImgUrl(tt?.logo)}
                          alt="img"
                        />
                      </div>
                      <div>
                        <div
                          className={
                            isMobile
                              ? 'blockChildTitleMobile'
                              : 'blockChildTitle'
                          }
                        >
                          {tt.title}
                        </div>
                        <UlLi texts={tt.list.map((ll: any) => ll.value)} />
                      </div>
                    </div>
                  )
                }
                return (
                  <div
                    key={tt.id}
                    style={{
                      margin: '0 32px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <div
                        className={
                          isMobile ? 'blockChildTitleMobile' : 'blockChildTitle'
                        }
                      >
                        {tt.title}
                      </div>
                      <UlLi texts={tt.list.map((ll: any) => ll.value)} />
                    </div>
                    <div style={{ position: 'relative', marginLeft: '24px' }}>
                      <img
                        alt="circle"
                        width={105}
                        height={105}
                        src={getImgUrl(tt?.logo)}
                      />
                    </div>
                  </div>
                )
              }
              return (
                <div key={tt.id} style={{ width: '330px' }}>
                  <div className="blockChildTitle">{tt.title}</div>
                  <div
                    style={{
                      position: 'relative',
                      marginTop: '56px',
                      textAlign: 'center',
                    }}
                  >
                    <img
                      alt="circle"
                      width={234}
                      height={234}
                      src={getImgUrl(tt?.logo)}
                    />
                  </div>
                  <UlLi texts={tt.list.map((ll: any) => ll.value)} />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default FunctionBlock
