import React, { FC } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import Introduce from 'app/pages/introduce'
import HeaderBlock from 'app/components/headerBlock'
import News from 'app/pages/news'
import Company from 'app/pages/company'
import Top from 'app/pages/top'
import UserPolicy from 'app/pages/privacyPolicy'
import CommercialPolicy from 'app/pages/commercialAct'
import NewDetail from 'app/pages/newDetail'

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HeaderBlock />}>
          <Route path="/" element={<Navigate to={'/elearning'} />} />
          <Route path="elearning" element={<Introduce />} />
          <Route path="news" element={<News />} />
          <Route path="company" element={<Company />} />
          <Route path="top" element={<Top />} />
          <Route path="privacy" element={<UserPolicy />} />
          <Route path="trade" element={<CommercialPolicy />} />
          <Route path="news-detail" element={<NewDetail />} />
        </Route>
        <Route path="*" element={<Navigate to={'/elearning'} />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
