import React, { FC, useState, useEffect } from 'react'

import { BlockTitle } from 'app/pages/introduce/FunctionBlock'
import { getICEC, getICECInfo } from './api'
import Language from 'app/container/language'
import { getImgUrl } from 'app/utils'
import liIcon from 'app/asset/li-icon.svg'

import useIsMobile from 'app/hooks'

const ImgCard: FC<{ data: any }> = ({ data }) => {
  const isMobile = useIsMobile()
  return (
    <div
      style={
        isMobile
          ? {
              width: '100%',
              height: '234px',
              padding: '24px',
              marginTop: '20px',
              borderRadius: '12px',
              boxSizing: 'border-box',
              opacity: '0.9',
              backgroundImage: `url(${getImgUrl(data?.image)})`,
              background:
                'linear-gradient(180deg, #E7F3FC 25.63%, rgba(255, 255, 255, 0.00) 99.77%), url(<path-to-image>), lightgray 50% / cover no-repeat',
            }
          : {
              borderRadius: '12px',
              width: '576px',
              height: '280px',
              marginTop: '48px',
              padding: '24px',
              boxSizing: 'border-box',
              opacity: '0.9',
              background:
                'linear-gradient(180deg, #E7F3FC 25.63%, rgba(255, 255, 255, 0.00) 99.77%), url(<path-to-image>), lightgray 50% / cover no-repeat',
              backgroundImage: `url(${getImgUrl(data?.image)})`,
            }
      }
    >
      <div
        style={
          isMobile
            ? {
                fontSize: '14px',
                lineHeight: '22px',
                color: '#234DA6',
                textAlign: 'center',
                fontWeight: 600,
              }
            : {
                color: '#234DA6',
                textAlign: 'center',
                fontSize: '28px',
                fontWeight: 600,
                lineHeight: '36px',
              }
        }
      >
        {data?.title}
      </div>
      <div
        style={
          isMobile
            ? {
                marginTop: '24px',
                color: '#2B2525',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '20px',
                fontFamily: 'ZenMaruGothic-light',
              }
            : {
                marginTop: '24px',
                color: '#2B2525',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                fontFamily: 'ZenMaruGothic-light',
              }
        }
      >
        {data?.content}
      </div>
    </div>
  )
}

const About: FC = () => {
  const [data, setData] = useState<any>()
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()
  const [infoData, setInfoData] = useState<any>()

  useEffect(() => {
    getICEC(language).then((res) => setData(res?.[0]?.attributes))
  }, [language])

  useEffect(() => {
    getICECInfo(language).then((res) => setInfoData(res))
  }, [language])

  return (
    <div
      style={
        isMobile
          ? { margin: '48px 30px 0' }
          : {
              width: 'max-content',
              margin: '0 auto',
              marginTop: '84px',
            }
      }
    >
      <BlockTitle
        style={{ textAlign: 'left' }}
        text={data?.['icec_intro_title'] || 'ICECについて'}
      />
      <div
        style={
          isMobile
            ? {
                fontSize: '12px',
                // color: '#234DA6',
                lineHeight: '20px',
                color: 'black',
                marginTop: '20px',
                textAlign: 'left',
              }
            : {
                width: '1200px',
                margin: '0 auto',
                textAlign: 'left',
                // color: '#234DA6',
                color: 'black',
                fontSize: '22px',
                lineHeight: '40px',
                fontWeight: 400,
                marginTop: '48px',
              }
        }
      >
        <div style={{ fontFamily: 'ZenMaruGothic-normal' }}>
          {data?.['icec_intro_content']}
        </div>
        <br />
        <div>
          {infoData
            ?.sort((a: any, b: any) => a.attributes.sort - b.attributes.sort)
            .map((tt: any) => {
              return (
                <div key={tt.attributes.name}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {' '}
                    <img
                      src={liIcon}
                      width={isMobile ? 6 : 12}
                      height={isMobile ? 6 : 12}
                      alt=""
                      style={{ marginRight: isMobile ? 6 : 12 }}
                    />{' '}
                    {tt.attributes.name}
                  </div>
                  <div
                    style={{
                      fontFamily: 'ZenMaruGothic-normal',
                      fontSize: isMobile ? '12px' : '22px',
                    }}
                  >
                    {tt.attributes.Values?.map((tt: any) => {
                      return <div key={tt.value}>{`・${tt.value}`}</div>
                    })}
                  </div>
                  <br />
                </div>
              )
            })}
        </div>
      </div>
      {/* <div
        style={
          isMobile
            ? {}
            : {
                display: 'flex',
                width: '1200px',
                flexFlow: 'wrap',
                justifyContent: 'space-between',
              }
        }
      >
        {data?.intro
          .sort((a: any, b: any) => a.sort - b.sort)
          .map((tt: any) => (
            <ImgCard data={tt} key={tt.id} />
          ))}
      </div> */}
    </div>
  )
}

export default About
