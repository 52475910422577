import React, { FC, useState, useEffect } from 'react'
import { Carousel, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

// import study1 from 'app/asset/study-1.png'
import study1 from 'app/asset/news-image.jpeg'
import study2 from 'app/asset/study-2.png'
import study3 from 'app/asset/study-3.png'
import textRight from 'app/asset/text-right.svg'
import { BlockTitle } from 'app/pages/introduce/FunctionBlock'
import Language from 'app/container/language'
import { getTopService } from './api'
import { getImgUrl } from 'app/utils'
import useIsMobile from 'app/hooks'

const Config = {
  1: [
    study1,
    'E学中文',
    'ICECは教育モデルの革新に取り組んでおり、人工知能、グローバルインターネットコミュニティ、クラウドビッグデータ、その他のテクノロジーを積極的に活用して時間と空間の限界を打ち破り、AI時代の教育の質を向上させ、世界の中国の教育に奉仕しています。',
  ],
  2: [
    study2,
    '中国語教師の育成',
    '「グローバルビジョン、継承とコミュニケーション、イノベーションリーダーシップ、共創と共創」による中国の教育コンサルティング業界の共生を促進し、専門的な教師トレーニングプログラム、キャリアプランニングと雇用の推奨、ビジネスモデルコンサルティングサービスを提供します。',
  ],
  3: [
    study3,
    '中国文化体験プログラム',
    '120年の歴史を持つ世界的に有名な横浜中華学校の活動資源を頼りに、6世代にわたる中国人学生と同窓会を動員して、質の高い中国文化活動と協力プログラムを幅広く提供しています。',
  ],
}

const CardImg: FC<{ data: any }> = ({ data }) => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  return (
    <div
      style={{
        width: isMobile ? 'calc(50% - 6px)' : '368px',
        height: isMobile ? '234px' : '518px',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '8px',
      }}
    >
      <img
        src={getImgUrl(data.image)}
        alt=""
        width={isMobile ? '100%' : 368}
        height={isMobile ? 108 : 286}
        loading="lazy"
      />
      <div
        style={{
          background: '#E7F3FC',
          textAlign: 'center',
          lineHeight: isMobile ? '18px' : '28px',
          borderRadius: '0px 0px 8px 8px',
          height: isMobile ? '152px' : '320px',
          position: 'absolute',
          top: isMobile ? '84px' : '222px',
          boxSizing: 'border-box',
          padding: isMobile ? '12px' : '24px 16px',
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div
          style={{
            color: '#234DA6',
            fontSize: isMobile ? '12px' : '20px',
            fontWeight: 600,
          }}
        >
          {data?.name}
        </div>
        <div
          className="textOverflow"
          style={{
            color: '#2B2525',
            fontSize: isMobile ? '10px' : '14px',
            fontWeight: 500,
            height: isMobile ? '72px' : '168px',
            marginTop: isMobile ? '8px' : '20px',
            textAlign: 'left',
            fontFamily: 'ZenMaruGothic-light',
          }}
        >
          {data?.summary}
        </div>
        <div>
          <Button
            type="link"
            onClick={() => {
              navigate(data?.link)
            }}
            style={{
              color: '#234DA6',
              fontSize: isMobile ? '10px' : '20px',
              fontWeight: 600,
            }}
          >
            {data?.link_title} &#8594;
          </Button>
        </div>
      </div>
    </div>
  )
}

const Seg: FC<{ data: any }> = ({ data }) => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  if (isMobile && data?.service) {
    return (
      <div style={{ width: '330px', margin: '0 auto' }}>
        {data?.service.map((ss: any) => (
          <div
            key={ss.name}
            style={{
              position: 'relative',
              height: '362px',
              borderRadius: '3px',
              marginBottom: '12px',
              overflow: 'hidden',
            }}
          >
            <img
              src={getImgUrl(ss.image)}
              style={{ borderRadius: '8px' }}
              width={330}
              height={242}
              alt=""
              loading="lazy"
            />
            <div
              style={{
                background: '#E7F3FC',
                textAlign: 'center',
                borderRadius: '0px 0px 8px 8px',
                position: 'absolute',
                top: '224px',
                width: 330,
                boxSizing: 'border-box',
                padding: '12px',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  color: '#234DA6',
                  fontSize: '12px',
                  fontWeight: 600,
                }}
              >
                {ss?.name}
              </div>
              <div
                className="textOverflow"
                style={{
                  color: '#2B2525',
                  fontSize: '10px',
                  fontWeight: 500,
                  marginTop: '8px',
                  lineHeight: '18px',
                  textAlign: 'left',
                }}
              >
                {ss?.summary}
              </div>
              <div style={{ textAlign: 'center', marginTop: '8px' }}>
                <Button
                  type="link"
                  style={{
                    color: '#234DA6',
                    fontSize: '10px',
                    fontWeight: 600,
                    lineHeight: '18px',
                  }}
                  onClick={() => {
                    navigate(ss?.link)
                  }}
                >
                  {ss?.link_title} &#8594;
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {data?.service.map((ss: any) => (
        <CardImg data={ss} key={ss.id} />
      ))}
    </div>
  )
}

const Content: FC = () => {
  const [data, setData] = useState<any>([])
  const { language } = Language.useContainer()
  const isMobile = useIsMobile()

  useEffect(() => {
    getTopService(language).then((res) => setData(res))
  }, [language])

  return (
    <div
      style={
        isMobile
          ? {
              margin: '48px 30px 0',
            }
          : {
              margin: '98px auto 0',
              maxWidth: '1200px',
            }
      }
    >
      <BlockTitle text={data[0]?.attributes.title || 'サービス内容'} />
      <Carousel
        style={{ width: '100%', marginTop: isMobile ? '20px' : '84px' }}
        dotPosition={data?.length > 1 ? 'right' : undefined}
        autoplay
        autoplaySpeed={6000}
      >
        {data
          ?.sort((a: any, b: any) => a.attributes.sort - b.attributes.sort)
          .map((tt: any) => (
            <Seg key={tt.id} data={tt.attributes} />
          ))}
      </Carousel>
    </div>
  )
}

export default Content
