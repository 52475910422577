import React, { FC, useEffect, useState } from 'react'

import { BlockTitle } from './FunctionBlock'
import useIsMobile from 'app/hooks'
import { getVideoSrc } from './api'
import Language from 'app/container/language'
import { getImgUrl } from 'app/utils'
import './video.less'

const Video: FC<{ title: string }> = ({ title }) => {
  const isMobile = useIsMobile()

  const [data, setData] = useState<any>([])
  const { language } = Language.useContainer()

  useEffect(() => {
    getVideoSrc(language).then((res) => setData(res[0]?.attributes))
  }, [language])

  useEffect(() => {
    document
      .getElementById('playButton')!
      .addEventListener('click', function () {
        var video = document.getElementById('myVideo')! as any
        if (video?.paused) {
          setTimeout(() => {
            video.play()
            this.style.display = 'none' // 隐藏播放按钮
          }, 150)
        } else {
          video.pause()
          this.style.display = 'block' // 显示播放按钮
        }
      })

    // 当视频开始播放时，隐藏播放按钮
    document.getElementById('myVideo')!.addEventListener('play', function () {
      document.getElementById('playButton')!.style.display = 'none'
    })

    // 当视频暂停时，显示播放按钮
    document.getElementById('myVideo')!.addEventListener('pause', function () {
      document.getElementById('playButton')!.style.display = 'block'
    })

    // 当视频播放结束时，显示播放按钮
    document.getElementById('myVideo')!.addEventListener('ended', function () {
      document.getElementById('playButton')!.style.display = 'block'
    })
  }, [])

  return (
    <div id="video" style={{ paddingTop: isMobile ? '60px' : '106px' }}>
      <BlockTitle text={title} />

      <div
        style={{
          width: isMobile ? 330 : 1000,
          height: isMobile ? 186 : 548,
          margin: isMobile ? '20px auto 0' : '46px auto 0',
          borderRadius: isMobile ? '8px' : '16px',
          overflow: 'hidden',
          border: isMobile ? '8px solid #8CB0FF' : '16px solid #8CB0FF',
        }}
        className="video-container"
      >
        <video
          id={'myVideo'}
          src={data?.media?.data?.attributes.url}
          width={isMobile ? 330 : 1000}
          height={isMobile ? 186 : 548}
          poster={getImgUrl(data?.cover)}
          style={{ objectFit: 'fill' }}
          preload={'auto'}
          controls
        />
        <div
          id={'playButton'}
          className="play-button"
          style={
            isMobile
              ? { width: '30px', height: '30px' }
              : { width: '64px', height: '64px' }
          }
        ></div>
      </div>
    </div>
  )
}

export default Video
