import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Button, ConfigProvider } from 'antd'
import zh_CN from 'antd/locale/zh_CN'
import ja_JP from 'antd/locale/ja_JP'
import { IntlProvider } from 'react-intl'

import zh from 'app/locale/zh_CN'
import ja from 'app/locale/ja_JP'
import en from 'app/locale/en_EN'
import Router from 'Router'
import 'App.less'
import Language from 'app/container/language'

function App() {
  return (
    <Language.Provider>
      <MainContent />
    </Language.Provider>
  )
}

const MainContent = () => {
  const { language } = Language.useContainer()

  useLayoutEffect(() => {
    document.body.style.fontFamily =
      language === 'ja-JP' ? 'ZenMaruGothic' : 'SourceHanSansSC-VF'
  }, [language])

  const languageConfig = {
    'ja-JP': ja,
    'zh-CN': zh,
    en: en,
  }

  return (
    <ConfigProvider
      locale={zh_CN}
      theme={{
        token: {
          colorPrimary: '#234DA6',
          colorLink: '#234DA6',
          fontFamily:
            language === 'ja-JP' ? 'ZenMaruGothic' : 'SourceHanSansSC-VF',
        },
      }}
    >
      <IntlProvider
        locale={language}
        messages={languageConfig[language as keyof typeof languageConfig]}
      >
        <Router />
      </IntlProvider>
    </ConfigProvider>
  )
}

export default App
