const Message = {
  new_registration: '注册',
  login: '登录',
  user_name: '姓名',
  contact_email: '联系邮箱',
  contact_phone: '联系电话',
  contact_content: '合作内容',
  company_name: '企业名称',
  school_name: '学校名称',
  position: '职位',
  person_cooperation: '个人合作',
  company_cooperation: '企业合作',
  school_cooperation: '学校合作',
  submit: '提交',
  submit_success: '提交成功',
  please_input: '请输入',
  contact_us: '立即咨询',
}

export default Message
